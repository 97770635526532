body {
}

h1,
h2,
h3,
h4,
h5,
p {
  padding: 0;
  margin: 0;
}

.btn-prm-web {
  font-weight: 700;
  color: white;
  background-color: var(--primary2);

  &:hover {
    background-color: var(--primary2A80);
  }
}

.btn-prm-web.big {
  font-size: 20px;
  padding: 20px 40px;
  border-radius: 30px;
}
