.web-service-dial-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  padding: 0 36px;
}

.web-service-dial-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.web-service-dial-btn {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1 1 0;
  font-size: 32px;
  font-weight: 500;
  color: var(--text1);
  height: 72px;
  border-radius: 20px;

  &:hover {
    background-color: var(--bg4);
  }
}

.web-service-dial-btn-hang-up-wrap {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1 1 0;
  height: 72px;

  &:hover {
    .web-service-dial-btn-hang-up {
      box-shadow: 0 16px 36px -16px var(--areaRed);
    }
  }
}

.web-service-dial-btn-hang-up {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 64px;
  height: 64px;
  border-radius: 32px;
  background-color: var(--areaRed);
}

.web-service-dial-hang-up {
  width: 40px;
  height: 40px;
}

.web-service-dial-btn-call {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1 1 0;
  height: 72px;
  border-radius: 20px;
}

.web-service-dial-btn-empty {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1 1 0;
  height: 72px;
  border-radius: 20px;
}

.web-service-dial-input-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
  min-height: 103px;
  flex: 1;
}

.web-service-dial-input {
  text-align: center;
  font-size: 28px;
  font-weight: 500;
  color: var(--text1);
}

.web-service-dial-name {
  font-size: 16px;
  font-weight: 500;
  color: var(--text1);
  min-width: 1px;
  height: 16px;
}

.web-service-dial-call {
  width: 64px;
}

.web-service-dial-char {
  width: 32px;
}

.web-service-dial-hide {
  font-size: 15px;
  font-weight: 500;
  color: var(--text1);
}

.web-service-calling-dial-wrap {
  display: flex;
  flex-direction: column;
  position: fixed;
  min-width: 304px;
  max-width: 440px;
  width: 100%;
  background-color: var(--bg3);
  z-index: 110;

  @media screen and (max-width: 510px) {
    max-width: none;
  }
}

.web-service-calling-dial-wrap.hide {
  height: 1px;
  opacity: 0;
  top: 100vh;

  .web-service-dial-wrap {
    display: none;
  }
}

.web-service-calling-dial-wrap.active {
  height: 100%;
  opacity: 1;
  top: 0;

  .web-service-dial-wrap {
    display: flex;
  }
}
