.web-service-receive-call-head {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  z-index: 100;
}

.web-service-receive-call-title {
  font-size: 20px;
  font-weight: 400;
  color: var(--text1);
  text-align: center;
}

.web-service-receive-call-profile {
  width: 96px;
  height: 96px;
  border-radius: 48px;
  object-fit: cover;
}

.web-service-receive-call-info-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
}

.web-service-receive-call-number {
  font-size: 28px;
  font-weight: 500;
  color: var(--text1);
}

.web-service-receive-call-name {
  font-size: 20px;
  font-weight: 500;
  color: var(--text1);
}

.web-service-receive-call-subtext-wrap {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}

.web-service-receive-call-position {
  font-size: 14px;
  font-weight: 400;
  color: var(--text1);
}

.web-service-receive-vertical-line {
  width: 1px;
  height: 12px;
  border-right: 2px solid var(--border3);
}

.web-service-receive-call-team {
  font-size: 14px;
  font-weight: 400;
  color: var(--text3);
}

.web-service-receive-call-btn-wrap {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  gap: 100px;
  height: 76px;
}

.web-service-receive-call-nocall-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 72px;
  height: 72px;
  border-radius: 36px;
  background-color: var(--areaRed);

  img {
    height: 48px;
    width: 48px;
  }

  &:hover {
    margin-bottom: 4px;
    box-shadow: 0 16px 36px -16px var(--areaRed);
  }
}

.web-service-receive-call-answer-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 72px;
  height: 72px;
  border-radius: 36px;
  background-color: var(--primary2);

  img {
    height: 48px;
    width: 48px;
  }

  &:hover {
    margin-bottom: 4px;
    box-shadow: 0 16px 36px -16px var(--primary2);
  }
}

.web-service-receive-call-bottom-sheet {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  margin-top: 56px;
  background-color: var(--bg2);
  width: 100%;
  min-height: calc(100vh - 486px);
  padding: 20px 20px 54px 20px;
  border-radius: 24px 24px 0 0;
  box-shadow: var(--bottomSheetShadow);
  z-index: 110;
}

.web-service-bottom-sheet-memo-text-wrap {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 16px;
  border-radius: 12px;
  gap: 8px;
  background-color: var(--bg3);

  &:hover {
    background-color: var(--bg4);
  }
}

.web-service-bottom-sheet-memo-date {
  font-size: 14px;
  font-weight: 400;
  color: var(--text3);
}

.web-service-bottom-sheet-memo-text {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.6;
  color: var(--text2);
}

.web-service-bottom-sheet-memo-title {
  width: 100%;
  font-size: 16px;
  font-weight: 700;
  text-align: left;
  color: var(--text1);
  padding: 8px 0;
}

.web-service-calling-btnset {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 284px;
  gap: 20px;
  margin-top: 100px;
}

.web-service-calling-button {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;

  &:hover {
    .web-service-calling-button-img-wrap {
      opacity: 0.6;
    }
  }
}

.web-service-calling-button-img-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 56px;
  height: 56px;
  background-color: var(--bg2);
  border-radius: 28px;
}

.web-service-calling-button-img-wrap.no-call {
  background-color: var(--areaRed);
}

.web-service-calling-button-img-wrap.active {
  background-color: var(--primary2);
}

.web-service-calling-button-img {
  width: 32px;
  height: 32px;
}

.web-service-calling-button-text {
  font-size: 12px;
  font-weight: 400;
  color: var(--text2);
}
