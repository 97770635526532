.web-service-login-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 36px;
  padding: 48px 36px;
}

.web-service-login-logo {
  width: 112px;
}

.web-service-login-inputset {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.web-service-login-inputset {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.web-service-login-loginset {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  gap: 12px;
}

.web-service-subbtnset {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
}
