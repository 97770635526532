.web-service-chat-date-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 0 4px 0;
  width: 100%;
}

.web-service-chat-date {
  padding: 8px 12px;
  border-radius: 12px;
  background-color: var(--bg5);
  font-size: 12px;
  font-weight: 400;
  color: var(--text2);
  width: fit-content;
}

.web-service-chat-bubble-set {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding: 4px 16px;
}

.web-service-chat-bubble-set.myBubble {
  justify-content: flex-end;
}

.web-service-chat-bubble-checkbox-wrap {
  display: none;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
}

.web-service-chat-bubble-checkbox-wrap.active {
  display: flex;
}

.web-service-chat-bubble-img-cover {
  display: flex;
  flex-direction: row;
  gap: 8px;
}

.web-service-chat-bubble-profile-img-wrap {
  position: relative;
  width: 36px;
  height: 36px;
  border-radius: 16.5px;
  overflow: hidden;
}

.web-service-chat-bubble-profile-img-wrap.show {
  cursor: pointer;

  &:hover {
    .web-service-chat-bubble-profile-overlay {
      opacity: 0.5;
    }
  }
}

.web-service-chat-bubble-profile-img {
  width: 36px;
  height: 36px;
  border: 0.5px solid var(--border3);
}

.web-service-chat-bubble-profile-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 36px;
  height: 36px;
  background-color: var(--blackA30);
  opacity: 0;
}

.web-service-chat-bubble-profile-name-cover {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.web-service-chat-bubble-profile-name {
  font-size: 12px;
  font-weight: 400;
  color: var(--text1);
}

.web-service-chat-bubble-text-cover.myBubble {
  display: flex;
  flex-direction: row-reverse;
  align-items: flex-end;
  gap: 4px;
  &:hover {
    .web-service-chat-bubble-text.myBubble {
      background-color: var(--areaBlueB90) !important;
    }
  }
}

.web-service-chat-bubble-text-cover.othersBubble {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  gap: 4px;

  &:hover {
    .web-service-chat-bubble-text,
    .web-service-chat-bubble-file {
      background-color: var(--bg3);
    }
    .web-service-chat-bubble-file-icon-wrap {
      background-color: var(--bg4);
    }
  }
}

.web-service-chat-bubble-text {
  cursor: text;
  user-select: text;
  flex: 1 1 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.33;
  color: var(--text1);
  padding: 8px 12px;
  max-width: 249px;
  background-color: var(--bg2);
  border-radius: 12px;

  @media screen and (max-width: 374px) {
    max-width: 198px;
  }
}

.web-service-chat-bubble-text.myBubble {
  color: var(--white);
  background-color: var(--areaBlue);
}

.web-service-chat-bubble-time {
  font-size: 10px;
  font-weight: 400;
  color: var(--text3);
  width: 45px;
  opacity: 1;
}

.web-service-chat-bubble-time.hide {
  opacity: 0;
}

.web-service-chat-bubble-time-area {
  position: relative;
  width: 45px;
  height: auto;
}

// Bubble (File)

.web-service-chat-bubble-file {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
  padding: 8px 12px 12px 12px;
  background-color: var(--bg2);
  border-radius: 12px;
  width: 249px;

  @media screen and (max-width: 374px) {
    width: 198px;
  }
}

.web-service-chat-bubble-file.myBubble {
  background-color: var(--areaBlue);

  .web-service-chat-bubble-file-icon-wrap {
    background-color: var(--areaBlueB90);
  }
}

.web-service-chat-bubble-file-icon-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 46px;
  height: 46px;
  border-radius: 8px;
  background-color: var(--bg3);
  margin-top: 4px;

  @media screen and (max-width: 374px) {
    width: 36px;
    height: 36px;
  }
}

.web-service-chat-bubble-file-icon {
  width: 32;
  height: 32px;

  @media screen and (max-width: 374px) {
    width: 24px;
    height: 24px;
  }
}

.web-service-chat-bubble-file-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 1 0;
  gap: 6px;
}

.web-service-chat-bubble-file-name {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.33;
  word-break: break-word;
  color: var(--text1);
}

.web-service-chat-bubble-file-name.myBubble {
  color: var(--white);
}

.web-service-chat-bubble-file-subInfo {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  gap: 8px;
  font-size: 11px;
  font-weight: 400;
  color: var(--text3);
}

.web-service-chat-bubble-file-subInfo.myBubble {
  color: var(--whiteA60);
}

// Bubble (Image)

.web-service-chat-bubble-image-wrap {
  cursor: pointer;
  position: relative;
  display: flex;
  flex-direction: row;
  gap: 1px;
  width: 249px;
  border-radius: 12px;
  overflow: hidden;

  @media screen and (max-width: 374px) {
    width: 198px;
  }
}

.web-service-chat-bubble-image-alone {
  max-width: 249px;
  max-height: 402px;

  @media screen and (max-width: 374px) {
    max-width: 198px;
    max-height: 320px;
  }
}

.web-service-chat-bubble-image-group-wrap-2 {
  display: flex;
  position: relative;
  max-height: 124px;
  max-width: 124px;
  overflow: hidden;
  flex: 1 1 0;

  @media screen and (max-width: 374px) {
    max-height: 99px;
    max-height: 99px;
  }
}

.web-service-chat-bubble-image-group-wrap-3 {
  display: flex;
  position: relative;
  max-height: 82px;
  max-width: 82px;
  overflow: hidden;
  flex: 1 1 0;

  @media screen and (max-width: 374px) {
    max-height: 66px;
    max-width: 66px;
  }
}

.web-service-chat-bubble-image-group-wrap-4 {
  display: flex;
  position: relative;
  max-height: 62px;
  max-width: 62px;
  overflow: hidden;
  flex: 1 1 0;

  @media screen and (max-width: 374px) {
    max-height: 49px;
    max-width: 49px;
  }
}

.web-service-chat-bubble-image-group-wrap-over {
  display: flex;
  position: relative;
  max-height: 62px;
  max-width: 62px;
  overflow: hidden;
  flex: 1 1 0;

  @media screen and (max-width: 374px) {
    max-height: 49px;
    max-width: 49px;
  }
}

.web-service-chat-bubble-image-group-2 {
  display: flex;
  position: relative;
  max-height: 124px;
  max-width: 124px;
  overflow: hidden;
  flex: 1 1 0;
  object-fit: cover;

  @media screen and (max-width: 374px) {
    max-height: 99px;
    max-width: 99px;
  }
}
.web-service-chat-bubble-image-group-3 {
  display: flex;
  position: relative;
  max-height: 82px;
  max-width: 82px;
  overflow: hidden;
  flex: 1 1 0;
  object-fit: cover;

  @media screen and (max-width: 374px) {
    max-height: 66px;
    max-width: 66px;
  }
}
.web-service-chat-bubble-image-group-4 {
  display: flex;
  position: relative;
  max-height: 62px;
  max-width: 62px;
  overflow: hidden;
  flex: 1 1 0;
  object-fit: cover;

  @media screen and (max-width: 374px) {
    max-height: 49px;
    max-width: 49px;
  }
}

.web-service-chat-bubble-image-group-over {
  display: flex;
  position: relative;
  max-height: 62px;
  max-width: 62px;
  overflow: hidden;
  flex: 1 1 0;
  object-fit: cover;

  @media screen and (max-width: 374px) {
    max-height: 49px;
    max-width: 49px;
  }
}

.web-service-chat-bubble-image-group-wrap-overlay {
  position: absolute;
  height: 100%;
  width: 100%;
  &:hover {
    background-color: var(--blackA30);
  }
}

.web-service-chat-bubble-image-group-over-4th-wrap {
  position: relative;
  width: 62px;
  height: 62px;

  @media screen and (max-width: 374px) {
    max-height: 49px;
    max-width: 49px;
  }
}

.web-service-chat-bubble-image-group-over-4th-overlay {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  background-color: var(--blackA30);
  color: var(--white);
  font-size: 16px;
  font-weight: 500;
  &:hover {
    background-color: rgba(0, 0, 0, 0.5);
  }
}

.web-service-chat-bubble-image-group-over-4th {
  display: flex;
  width: 100%;
  height: 100%;
  overflow: hidden;
  flex: 1 1 0;
  object-fit: cover;
}

// Bubble (URL)
.web-service-chat-bubble-url-group {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;

  &:hover {
    .web-service-chat-bubble-url {
      background-color: var(--bg3);
    }
    .web-service-chat-bubble-url.myBubble {
      background-color: var(--areaBlueB90);
    }
    .web-service-chat-bubble-url-thumbnail-text-wrap {
      background-color: var(--bg3);
    }
    .web-service-chat-bubble-url-thumbnail-text-wrap.myBubble {
      background-color: var(--areaBlueB90);
    }
  }
}

.web-service-chat-bubble-url-group.myBubble {
  align-items: flex-end;
}

.web-service-chat-bubble-url {
  font-size: 14px;
  font-weight: 400;
  line-height: 1.33;
  color: var(--textBlue);
  text-decoration: underline;
  padding: 8px 12px;
  max-width: 249px;
  width: fit-content;
  background-color: var(--bg2);
  border-radius: 12px;

  @media screen and (max-width: 374px) {
    max-width: 198px;
  }
}

.web-service-chat-bubble-url.myBubble {
  color: var(--white);
  background-color: var(--areaBlue);
}

.web-service-chat-bubble-url-thumbnail {
  width: 249px;
  overflow: hidden;
  border-radius: 12px;

  @media screen and (max-width: 374px) {
    width: 198px;
  }
}

.web-service-chat-bubble-url-thumbnail-image-wrap {
  width: 100%;
  height: 125px;

  @media screen and (max-width: 374px) {
    height: 99px;
  }
}

.web-service-chat-bubble-url-thumbnail-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.web-service-chat-bubble-url-thumbnail-text-wrap {
  display: flex;
  flex-direction: column;
  gap: 4px;
  background-color: var(--bg2);
  padding: 12px 12px 16px 12px;
}

.web-service-chat-bubble-url-thumbnail-text-wrap.myBubble {
  background-color: var(--areaBlue);

  .web-service-chat-bubble-url-thumbnail-name,
  .web-service-chat-bubble-url-thumbnail-url {
    color: var(--white);
  }
}

.web-service-chat-bubble-url-thumbnail-name {
  font-size: 14px;
  font-weight: 400;
  line-height: 1.3;
  color: var(--text1);
}

.web-service-chat-bubble-url-thumbnail-url {
  font-size: 11px;
  font-weight: 400;
  color: var(--text4);
}

// Bubble Delete Mode
.web-service-chat-bubble-set.active.myBubble {
  justify-content: space-between;
}

.web-service-chat-bubble-set.active {
  cursor: pointer;
  align-items: center;
  padding: 4px 16px 4px 6px;

  .web-service-chat-bubble-profile-name-cover {
    pointer-events: none;
  }

  &:hover {
    background-color: var(--bg5);
  }
}

.web-service-chat-bubble-set.lastBubble {
  margin-bottom: 8px;
}

// Bottom Fixed Input
.web-service-chat-input-container {
  display: flex;
  flex-direction: column;
  gap: 2px;
  position: fixed;
  bottom: 0;
  background-color: var(--bg2);
  width: calc(100% - 135px);
  min-height: 50px;
  max-width: 440px;
  min-width: 304px;
  padding: 6px 12px 6px 2px;
  box-shadow: var(--bottomSheetShadow);

  @media screen and (max-width: 510px) {
    max-width: none;
    width: 100%;
  }
}

.web-service-chat-input-area {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2px;
  width: 100%;
  min-height: 50px;

  @media screen and (max-width: 510px) {
    max-width: none;
    width: 100%;
  }
}

.web-service-chat-input-more-button {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 38px;
  height: 38px;
  border-radius: 8px;

  &:hover {
    background-color: var(--bg4);
  }
}

.web-service-chat-input-more-icon {
  width: 32px;
  height: 32px;
}

.web-service-chat-input-wrap {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 4px;
  position: relative;
  flex: 1 1 0;
  background-color: var(--bg3);
  border: 0.5px solid var(--border3);
  border-radius: 12px;
  padding: 0px 0px 0px 12px;
}

.web-service-chat-input {
  cursor: text;
  display: flex;
  flex: 1 1 0;
  align-items: center;
  justify-content: center;
  min-height: 20px;
  max-height: 82px;
  margin: 5px 0 3px 0;
  width: 100%;
  color: var(--text1);
  font-size: 14px;
  font-weight: 400;
  line-height: 1.33;
  resize: none;
}

.web-service-chat-input::-webkit-scrollbar {
  width: 12px;
  height: 8px;
  border-radius: 4px;
}

.web-service-chat-input::-webkit-scrollbar-thumb {
  background-color: var(--bg5);
  border-radius: 4px;
  border: 2px solid var(--bg3);
  background-clip: padding-box;

  &:hover {
    background-color: var(--bg4);
  }
}

.web-service-chat-input::-webkit-scrollbar-track {
  background-color: var(--bg3);
  border-radius: 4px;
}

.web-service-chat-input-submit-wrap {
  cursor: pointer;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 38px;
  height: 38px;

  &:hover {
    .web-service-chat-input-submit {
      background-color: var(--primary2A80);
    }
  }
}

.web-service-chat-input-submit {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 10px;
  background-color: var(--primary2);
}

// Pop over (Bubble Click 시)

.web-service-chat-bubble-set.othersBubble .web-service-chat-bubble-click-pop,
.web-service-chat-bubble-set.myBubble .web-service-chat-bubble-click-pop {
  position: absolute;
  display: flex;
  flex-direction: row;
  border-radius: 12px;
  width: 0;
  height: 0;
  overflow: hidden;
  opacity: 0;
}

.web-service-chat-bubble-set.othersBubble .web-service-chat-bubble-click-pop {
  left: 0px;
  bottom: 0px;
}

.web-service-chat-bubble-set.myBubble .web-service-chat-bubble-click-pop {
  right: 0px;
  bottom: 0px;
}

.web-service-chat-bubble-set.othersBubble
  .web-service-chat-bubble-click-pop.active,
.web-service-chat-bubble-set.myBubble
  .web-service-chat-bubble-click-pop.active {
  opacity: 1;
  width: auto;
  height: auto;
}

.web-service-chat-bubble-set.othersBubble
  .web-service-chat-bubble-click-pop-icon-wrap {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 4px;
  padding: 8px;

  background-color: var(--bg2);

  &:hover {
    background-color: var(--bg3);
  }
}

.web-service-chat-bubble-set.myBubble
  .web-service-chat-bubble-click-pop-icon-wrap {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 4px;
  padding: 8px;

  background-color: var(--areaBlue);

  &:hover {
    background-color: var(--areaBlueB90);
  }
}

.web-service-chat-bubble-click-pop-icon {
  width: 16px;
  height: 16px;
}

.web-service-chat-bubble-click-pop-icon-name {
  font-size: 12px;
  font-weight: 400;
  color: var(--text1);
}

// Reply

.web-service-chat-reply-wrap {
  display: flex;
  flex-direction: row;
  padding: 8px 0 8px 14px;
}

.web-service-chat-reply-contents {
  display: flex;
  flex-direction: column;
  gap: 8px;
  flex: 1 1 0;
}

.web-service-chat-reply-target {
  font-size: 14px;
  font-weight: 500;
  color: var(--primary1);
}

.web-service-chat-reply-text {
  font-size: 14px;
  font-weight: 400;
  line-height: 1.33;
  color: var(--text3);
}

.web-service-chat-reply-close-button-area {
  cursor: pointer;
  width: 44px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;

  &:hover {
    background-color: var(--bg3);
  }
}

.web-service-chat-reply-close-button {
  width: 24px;
  height: 24px;
}
