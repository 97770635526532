body {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

::-webkit-scrollbar {
  width: 16px;
  height: 8px;
  border-radius: 6px;
}
::-webkit-scrollbar-thumb {
  background-color: var(--bg3);
  border-radius: 8px;
  border: 4px solid transparent;
  background-clip: padding-box;

  &:hover {
    background-color: var(--bg35);
  }
}
::-webkit-scrollbar-track {
  background-color: var(--bg5);
}

.web-service-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  min-height: 100vh;
  background-color: var(--bg5);
}

.web-service-container.calling {
  background: linear-gradient(
    -60deg,
    var(--primary1),
    var(--primary2),
    #0c7ff2
  );
  background-size: 400% 400%;
  animation: gradient 10s linear 0s alternate infinite none running;
  min-height: 100vh;
  height: 100%;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.web-service-container-row {
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: 575px;
}

.web-service-container-row.nav-hide {
  max-width: 440px;
}

.web-service-contents-container {
  position: relative;
  min-width: 304px;
  max-width: 440px;
  width: 100%;
  background-color: var(--bg3);
  margin-left: 135px;

  @media screen and (max-width: 510px) {
    max-width: none;
    margin-left: 0;
  }
}

.web-service-contents-container.nav-hide {
  margin-left: 0;
}

// Input

.web-service-input-wrap {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  width: 100%;
}

.web-service-input-label {
  color: var(--text2);
}

.web-service-input-label.false {
  display: none;
}

.web-service-input {
  width: 100%;
  height: 56px;
  padding: 0 16px;
  border: 2px solid var(--border3);
  border-radius: 12px;
  color: var(--text1);

  &:focus {
    border: 2px solid var(--reverseLight);
  }

  &::placeholder {
    color: var(--text4);
  }
}

// Input Type withRomove

.web-service-input-delete-wrap {
  cursor: pointer;
  position: absolute;
  right: 2px;
  top: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 44px;
  height: 44px;

  &:hover {
    .web-service-input-delete {
      opacity: 0.3;
    }
    .web-service-input-delete.hover-effect {
      opacity: 0.7;
    }
  }
}

.web-service-input-delete {
  position: absolute;
  width: 24px;
  opacity: 1;
}
.web-service-input-delete.hover-effect {
  position: absolute;
  opacity: 0;
}

// Input Type InnerButton
.web-service-input-innerButton-wrap {
  cursor: pointer;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 8px;
  top: 8px;
  height: 40px;
  padding: 0 16px;
  border-radius: 8px;
  background-color: var(--bg4);
  opacity: 0.5;

  font-size: 14px;
  font-weight: 700;
  color: var(--text2);

  &:hover {
    background-color: var(--bg5);
  }
}

.web-service-input-innerButton-wrap.active {
  opacity: 1;
}

// Input Type Time
.web-service-input-timer-wrap {
  position: absolute;
  right: 16px;
  top: 20px;
  font-size: 16px;
  font-weight: 400;
  color: var(--textRed);
}

// CheckBox
.web-service-checkbox-wrap {
  cursor: pointer;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
  height: 44px;

  &:hover {
    .web-service-checkbox-img-wrap.false {
      .web-service-checkbox-false.hover-effect {
        position: absolute;
        opacity: 0.7;
      }

      .web-service-checkbox-true.hover-effect {
        position: absolute;
        opacity: 0.3;
      }
    }
  }

  &:hover {
    .web-service-checkbox-img-wrap.true {
      .web-service-checkbox-false.hover-effect {
        position: absolute;
        opacity: 0.3;
      }

      .web-service-checkbox-true.hover-effect {
        position: absolute;
        opacity: 0.7;
      }
    }
  }
}

.web-service-checkbox-img-wrap.true {
  .web-service-checkbox-false {
    position: absolute;
    opacity: 0;
  }

  .web-service-checkbox-true {
    position: absolute;
    opacity: 1;
  }
}

.web-service-checkbox-img-wrap.false {
  .web-service-checkbox-false {
    position: absolute;
    opacity: 1;
  }

  .web-service-checkbox-true {
    position: absolute;
    opacity: 0;
  }
}

.web-service-checkbox-img-wrap {
  position: relative;
  width: 20px;
  height: 20px;
}

.web-service-checkbox-img-wrap.true {
}

.web-service-checkbox-false,
.web-service-checkbox-true {
  position: absolute;
  width: 20px;
  height: 20px;
}

.web-service-checkbox-label {
  color: var(--text1);
}

// Radio

.web-service-radio {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  border: 2px solid var(--border3);
}

.web-service-radio.active {
  border: 2px solid var(--primary2);
}

.web-service-radio-inner {
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: var(--primary2);
}

// Button
.web-service-primary-button {
  width: 100%;
  height: 56px;
  border-radius: 12px;
  background-color: var(--primary2);
  color: var(--white);
  font-size: 16px;
  font-weight: 700;

  &:hover {
    background-color: var(--primary2A80);
    box-shadow: 0 24px 36px -20px var(--primary2A60);
  }
}

.web-service-primary-button.disabled {
  cursor: no-drop;
  opacity: 0.5;
}

.web-service-secondary-button.small {
  height: 38px;
  padding: 0 14px;
  border-radius: 8px;
  background-color: var(--bg4);
  color: var(--text2);
  font-size: 14px;
  font-weight: 500;

  &:hover {
    background-color: var(--bg5);
  }
}

.web-service-primary-button.small {
  width: fit-content;
  font-size: 14px;
  padding: 12px 24px;
  height: 38px;
}

.web-service-secondary-button.normal {
  width: 100%;
  height: 56px;
  border-radius: 12px;
  background-color: var(--bg4);
  color: var(--text2);
  font-size: 16px;
  font-weight: 700;

  &:hover {
    background-color: var(--bg5);
  }
}

.web-service-secondary-button.normal.up {
  width: 100%;
  height: 56px;
  border-radius: 12px;
  background-color: var(--bg3);
  color: var(--text2);
  font-size: 16px;
  font-weight: 700;

  &:hover {
    background-color: var(--bg4);
  }
}

// Button (Close Circle Button)

.web-service-close-circle-clickarea {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  .web-service-close-circle {
    position: absolute;
    opacity: 1;
  }

  .web-service-close-circle.hover-effect {
    position: absolute;
    opacity: 0;
  }

  &:hover {
    .web-service-close-circle {
      opacity: 0;
    }

    .web-service-close-circle.hover-effect {
      opacity: 1;
    }
  }
}

// Modal

.web-service-modal-bg {
  position: fixed;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 0 16px;
  background-color: var(--blackA50);
  z-index: 120;
}

.web-service-modal-container {
  position: absolute;
  top: auto;
  left: auto;
  display: flex;
  flex-direction: column;
  padding: 0 20px 20px 20px;
  max-width: 408px;
  width: 100%;
  border-radius: 32px;
  background-color: var(--bg3);
}

.web-service-modal-title-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 14px;
  font-weight: 700;
  height: 42px;
  border-bottom: 0.5px solid var(--border3);
  color: var(--text1);
}

.web-service-modal-text-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  padding: 36px 0;
  color: var(--text1);
}

.web-service-modal-btn-wrap {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
}

// Modal Alarm
.web-service-alarm-modal {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  left: calc(135px - 64px + (100% - 135px) / 2);
  top: calc(50vh - 32px - 56px);
  width: 0;
  height: 0;
  overflow: hidden;
  border-radius: 24px;
  background-color: var(--bg3);
  opacity: 0;
  box-shadow: 0 16px 48px -12px var(--blackA50);
  transition: opacity 0.22s var(--cubiz-normal);
  z-index: 90;

  @media screen and (max-width: 510px) {
    left: calc(50% - 64px);
  }
}
.web-service-alarm-modal.active {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  left: calc(135px - 64px + (100% - 135px) / 2);
  top: calc(50vh - 32px - 56px);
  width: 128px;
  height: 128px;
  border-radius: 24px;
  background-color: var(--bg3);
  opacity: 0.8;
  box-shadow: 0 16px 48px -12px var(--blackA50);
  z-index: 130;

  .web-service-alarm-modal-icon {
    opacity: 1;
  }

  @media screen and (max-width: 510px) {
    left: calc(50% - 64px);
  }
}

.web-service-alarm-modal-icon {
  width: 64px;
  height: 64px;
  opacity: 0;
  transition: opacity 0.22s var(--cubiz-normal);
}

// Modal Bookmark

.web-service-bookmark-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  left: calc(135px - 64px + (100% - 135px) / 2);
  top: calc(50vh - 32px - 56px);
  width: 0;
  height: 0;
  overflow: hidden;
  border-radius: 24px;
  background-color: var(--bg3);
  opacity: 0;
  box-shadow: 0 16px 48px -12px var(--blackA30);
  transition: opacity 0.22s var(--cubiz-normal);
  z-index: 90;

  @media screen and (max-width: 510px) {
    left: calc(50% - 64px);
  }
}

.web-service-bookmark-modal.active {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  gap: 8px;
  left: calc(135px - 64px + (100% - 240px) / 2);
  top: calc(50vh - 32px - 56px);
  width: 240px;
  height: 128px;
  padding: 36px;
  border-radius: 24px;
  background-color: var(--bg3);
  opacity: 1;
  box-shadow: 0 16px 48px -12px var(--blackA30);
  z-index: 130;

  .web-service-bookmark-modal-icon {
    opacity: 1;
  }

  @media screen and (max-width: 510px) {
    left: calc(50% - 64px);
  }
}

.web-service-bookmark-modal-icon {
  width: 64px;
  height: 64px;
  opacity: 0;
  transition: opacity 0.22s var(--cubiz-normal);
}

.web-service-bookmark-modal-text {
  font-size: 14px;
  font-weight: 400;
  line-height: 1.33;
  color: var(--text2);
}

// Body

.web-service-normal-body {
  display: flex;
  flex-direction: column;
  padding-top: 54px;
  min-height: 100vh;
}

.web-service-normal-body.bottom-button {
  padding-bottom: 178px;
}

.web-service-normal-body.bottom-nav {
  padding-bottom: 161px;
}

.web-service-normal-body.search {
  padding-bottom: 64px;
  padding-top: 54px;
}

.web-service-normal-body.call {
  padding-top: 48px;
}

.web-service-normal-body.call-log {
  padding-top: 54px;
  padding-bottom: 64px;
}

.web-service-normal-body.call-log.overlay {
  padding-top: 54px;
  overflow: hidden;
  height: 100vh;
}

.web-service-normal-body.bg4 {
  background-color: var(--bg4);
}

.web-service-normal-body.chat {
  gap: 0px;
  padding: 78px 0 124px 0;
}

.web-service-normal-body.chat.overlay {
  overflow: hidden;
  height: 100vh;
}

.web-service-normal-body.contact {
  padding: 78px 0 100px 0;
}

.web-service-normal-body.normal {
  padding: 54px 0 100px 0;
}

.web-service-normal-body.normal-78 {
  padding: 78px 0 100px 0;
}

.web-service-normal-body.more {
  padding: 54px 0 100px 0;
  background-color: var(--bg4);
}

.web-service-normal-body.more-78 {
  padding: 78px 0 100px 0;
  background-color: var(--bg4);
}

.web-service-normal-body.overlay {
  overflow: hidden;
  height: 100vh;
}

// Header

.web-service-icon-button-clickarea {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 44px;
  min-width: 44px;
  border-radius: 8px;
  color: var(--text1);

  &:hover {
    background-color: var(--bg4);
  }
}

.web-service-header-icon-button-img {
  width: 32px;
  height: 32px;
}

// Center Title Header

.web-service-center-title-header-container {
  position: fixed;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 54px;
  width: calc(100% - 135px);
  max-width: 440px;
  min-width: 304px;
  padding: 0 4px;
  background-color: var(--bg3A95);
  z-index: 110;

  @media screen and (max-width: 510px) {
    max-width: none;
    width: 100%;
  }
}

.web-service-center-title-header-left {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 44px;
  min-width: 44px;
  border-radius: 8px;

  &:hover {
    background-color: var(--bg4);
  }
}

.web-service-center-title-left-icon {
  width: 32px;
}

.web-service-center-title-header-center {
  margin: 0 auto;
  text-align: center;
}

.web-service-center-title-header-center {
  margin: 0 auto;
  text-align: center;
}

.web-service-center-title-header-title {
  font-size: 16px;
  font-weight: 500;
  color: var(--text1);
}

.web-service-center-title-header-right {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 44px;
  min-width: 44px;
  border-radius: 8px;
}

.web-service-center-title-header-right.useArea {
  cursor: pointer;

  &:hover {
    background-color: var(--bg4);
  }
}

// Left Title Header

.web-service-left-title-header-container {
  position: fixed;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 54px;
  width: calc(100% - 135px);
  max-width: 440px;
  min-width: 304px;
  padding: 0 4px 0 16px;
  background-color: var(--bg3A95);
  z-index: 110;

  @media screen and (max-width: 510px) {
    max-width: none;
    width: 100%;
  }
}

.web-service-left-title-header-right {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
}

.web-service-group-call-clickarea {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 44px;

  &:hover {
    .web-service-group-call-button {
      background-color: var(--bg5);
    }
  }
}

.web-service-group-call-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  background-color: var(--bg4);
  padding: 6px 10px 6px 8px;
  border-radius: 13px;
}

.web-service-group-call-icon {
  width: 14px;
}

.web-service-group-call-text {
  font-size: 13px;
  font-weight: 500;
  color: var(--text2);
}

.web-service-left-title-header-title {
  font-size: 16px;
  font-weight: 500;
  color: var(--text1);
}

.web-service-left-title-right-icon {
  width: 32px;
}

// Search Header

.web-service-search-header-container {
  position: fixed;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 54px;
  width: calc(100% - 135px);
  max-width: 440px;
  min-width: 304px;
  padding: 0 8px 0 16px;
  background-color: var(--bg3A95);
  z-index: 110;

  @media screen and (max-width: 510px) {
    max-width: none;
    width: 100%;
  }
}

.web-service-search-header-input {
  display: flex;
  flex: 1;
  height: 54px;
  color: var(--text1);
}

.web-service-search-header-delete {
  width: 24px;
}

// Call Log Header

.web-service-callLog-header-container {
  position: fixed;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 54px;
  width: calc(100% - 135px);
  max-width: 440px;
  min-width: 304px;
  padding: 0 4px 0 16px;
  background-color: var(--bg3A95);
  z-index: 110;

  @media screen and (max-width: 510px) {
    max-width: none;
    width: 100%;
  }
}

.web-service-callLog-header-filter {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  height: 38px;
  padding: 0 14px;
  background-color: var(--bg4);
  border-radius: 12px;
  font-size: 16px;
  font-weight: 500;
  color: var(--text1);

  &:hover {
    background-color: var(--bg5);
  }
}

.web-service-callLog-header-arrow-bottom {
  width: 16px;
}

// Subtitle

.web-service-sub-title-wrap {
  padding: 0 16px;
  width: 100%;
}

.web-service-subtitle {
  font-size: 14px;
  font-weight: 700;
  color: var(--text2);
  padding: 20px 0;
}

.web-service-subtitle.underline {
  border-bottom: 0.5px solid var(--border3);
}

// Bottom Sheet

.web-service-sheet-area {
  max-height: 0;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 120;
  overflow: hidden;
  transition: background-color 0.22s var(--cubiz-normal);
}

.web-service-sheet-area.active {
  max-height: 100vh;
}

.web-service-bottom-sheet-bar {
  width: 64px;
  height: 6px;
  border-radius: 3px;
  background-color: var(--bg4);
}

.web-service-bottom-sheet-dropdown-container {
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 0;
  bottom: auto;
  width: 100%;
  background-color: var(--bg3);
  padding: 36px 0;
  border-radius: 16px 16px 0 0;
  box-shadow: var(--bottomSheetShadow);
  overflow: hidden;
}

.web-service-bottom-sheet-dropdown-container.active {
  bottom: 0;
}

.web-service-bottom-sheet-dropdown-item-wrap {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 54px;
  padding: 0 20px;
  font-size: 16px;
  font-weight: 400;
  color: var(--text1);

  &:hover {
    background-color: var(--bg4);
  }
}

.web-service-bottom-sheet-dropdown-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex: 1 1 0;
  height: 100%;
  border-bottom: 0.5px solid var(--border3);
}

.web-service-bottom-sheet-dropdown-item.selected {
  font-weight: 500;
  color: var(--primary1);
}

.web-service-bottom-sheet-dropdown-item-selectText {
  font-size: 16px;
  font-weight: 500;
  color: var(--primary1);
}

.web-service-bottom-sheet-button-conatiner {
  position: fixed;
  bottom: 0;
  max-width: 440px;
  min-width: 304px;
  width: calc(100% - 135px);
  background-color: var(--bg3A95);
  box-shadow: var(--bottomSheetShadow);
  padding: 12px 16px;

  @media screen and (max-width: 510px) {
    width: 100%;
    max-width: none;
  }
}

// Bottom Sheet Record

.web-service-bottom-sheet-record-container {
  position: absolute;
  display: flex;
  flex-direction: column;
  gap: 20px;
  bottom: -300px;
  width: 100%;
  background-color: var(--bg3);
  padding: 0 0 36px 0;
  box-shadow: var(--bottomSheetShadow);
  overflow: hidden;
  z-index: 130;
}

.web-service-bottom-sheet-record-container.active {
  bottom: 0;
}

.web-service-bottom-sheet-record-close-area {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
}

.web-service-bottom-sheet-record-body-area {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 0 16px;
  gap: 16px;
}

.web-service-bottom-sheet-record-player-set {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
}

.web-service-bottom-sheet-record-player-bar {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 8px;
  background-color: var(--bg4);
}

.web-service-bottom-sheet-record-player-current {
  width: 260px;
  height: 8px;
  background-color: var(--primary2);
}

.web-service-bottom-sheet-record-player-handle {
  position: relative;
  left: -8px;
  cursor: pointer;
  width: 16px;
  height: 16px;
  border-radius: 8px;
  background-color: var(--white);
  box-shadow: 0 0 12px 0 var(--bg5);
}

.web-service-bottom-sheet-record-playtime {
  font-size: 12px;
  font-weight: 500;
  color: var(--text2);
}

.web-service-bottom-sheet-record-play-button {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  border-radius: 24px;
  background-color: var(--primary2);

  &:hover {
    background-color: var(--primary2A60);
  }

  img {
    width: 32px;
  }
}

.web-service-bottom-sheet-record-see-text {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 38px;
  padding: 0 14px;
  border-radius: 8px;
  background-color: var(--bg4);

  &:hover {
    background-color: var(--bg5);
  }
}

// Top Sheet Menu Container

.web-service-top-sheet-menu-container {
  position: absolute;
  display: flex;
  flex-direction: column;
  top: -72px;
  right: 0;
  bottom: auto;
  width: calc(100% - 40px);
  background-color: var(--bg3);
  padding: 8px 0;
  margin: 20px;
  border-radius: 16px;
  box-shadow: var(--bottomSheetShadow);
  overflow: hidden;
}

.web-service-top-sheet-menu-container.active {
  top: 0;
}

.web-service-top-sheet-menu-item-wrap {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 54px;
  padding: 0 20px;
  font-size: 16px;
  font-weight: 400;
  color: var(--text1);
  overflow: hidden;

  &:hover {
    background-color: var(--bg4);
  }
}

.web-service-top-sheet-menu-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex: 1 1 0;
  height: 100%;
  border-top: 0.5px solid var(--border3);
}

.web-service-top-sheet-menu-item.first {
  border-top: none;
}

.web-service-top-sheet-menu-item-subText {
  font-size: 16px;
  font-weight: 500;
  color: var(--primary1);
}

.web-service-top-sheet-menu-item-selectText {
  font-size: 16px;
  font-weight: 500;
  color: var(--primary1);
}

// Overlay

.web-service-overlay {
  width: 100%;
  height: 100%;
  background-color: var(--blackA50);
  opacity: 0;
}

.web-service-overlay.active {
  opacity: 1;
}

// Switch

.web-service-switch-button {
  display: flex;
  position: relative;
  width: 40px;
  height: 24px;
  border-radius: 14px;
  padding: 2px;
  background-color: var(--bg5);
}

.web-service-switch-button.active {
  background-color: var(--primary2);
}

.web-service-switch-wheel {
  width: 20px;
  height: 20px;
  border-radius: 10px;
  background-color: var(--white);
}

.web-service-switch-wheel.active {
  margin-left: 16px;
}

// Script

.web-service-script-wrap {
  display: flex;
  flex-direction: column;
  padding: 36px 16px;
  gap: 10px;
}

.web-service-script-title {
  font-size: 20px;
  font-weight: 700;
  line-height: 1.33;
  color: var(--text1);
}

.web-service-script-text {
  font-size: 14px;
  font-weight: 400;
  line-height: 1.66;
  color: var(--text2);
}

// Title

.web-service-page-title {
  font-size: 20px;
  font-weight: 700;
  color: var(--text1);
  padding: 12px 16px;
  margin-bottom: 12px;
}

.web-service-memo-card-set {
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 0 16px;
}

.web-service-memo-card {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
  border-radius: 12px;
  padding: 16px;
  background-color: var(--bg2);

  &:hover {
    box-shadow: 0 4px 24px var(--bg4);
  }
}

.web-service-memo-card-text-wrap {
  display: flex;
  flex-direction: column;
  gap: 6px;
  overflow: hidden;
}

.web-service-memo-card-text {
  font-size: 15px;
  font-weight: 400;
  color: var(--text2);
  line-height: 1.33;
  max-height: 40px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.web-service-memo-card-time {
  font-size: 13px;
  font-weight: 400;
  color: var(--text3);
}

// Profile

@keyframes calling {
  0% {
    box-shadow: 0 0 0 0 rgba(20, 204, 186, 1);
  }
  50% {
    box-shadow: 0 0 12px 0 rgba(20, 204, 186, 0.8);
  }
  100% {
    box-shadow: 0 0 24px 0 rgba(20, 204, 186, 0);
  }
}

.web-service-profile-wrap.calling {
  .web-service-profile-img {
    animation-duration: 3s;
    animation-name: calling;
    animation-iteration-count: infinite;
    background-color: red;
  }
}

.web-service-profile-wrap.small {
  width: 36px;
  height: 36px;
  border-radius: 16px;

  .web-service-profile-img {
    width: 36px;
    height: 36px;
    border-radius: 16px;
    object-fit: cover;
  }

  .web-service-profile-state {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 11px;
    height: 11px;
    border-radius: 6px;
    border: 0.5px solid var(--border3);
  }
}

.web-service-profile-wrap.medium {
  width: 53px;
  height: 53px;
  border-radius: 23px;

  .web-service-profile-img {
    width: 53px;
    height: 53px;
    border-radius: 23px;
    object-fit: cover;
  }

  .web-service-profile-state {
    position: absolute;
    bottom: 1px;
    right: 1px;
    width: 14px;
    height: 14px;
    border-radius: 7px;
    border: 0.5px solid var(--border3);
  }
}

.web-service-profile-wrap.big {
  width: 96px;
  height: 96px;
  border-radius: 42px;

  .web-service-profile-img {
    width: 96px;
    height: 96px;
    border-radius: 42px;
    object-fit: cover;
  }

  .web-service-profile-state {
    position: absolute;
    bottom: 3px;
    right: 3px;
    width: 20px;
    height: 20px;
    border-radius: 12px;
    border: 0.5px solid var(--border3);
  }
}

.web-service-profile-wrap-relative {
  position: relative;
}

.web-service-profile-state.online {
  background-color: var(--primary2);
}

.web-service-profile-state.offline {
  background-color: var(--bg5);
}

.web-service-profile-state.idle {
  background-color: var(--starYellow);
}

.web-service-profile-state.DNdisturb {
  background-color: var(--areaRed);
}

.web-service-profile-state.none {
  display: none;
}
.web-service-profile-wrap.small {
  .web-service-profile-state {
    img {
      width: 11px;
      height: 11px;
    }
  }
}

.web-service-profile-state.calling {
  animation-name: calling-phone;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-outcubic-bezier(0.34, 1.56, 0.64, 1);
  background: var(--primary2);
}

@keyframes calling-phone {
  0% {
    rotate: -10deg;
  }
  5% {
    rotate: 10deg;
  }
  10% {
    rotate: -10deg;
  }
  15% {
    rotate: 10deg;
  }
  20% {
    rotate: -10deg;
  }
  25% {
    rotate: 10deg;
  }
  30% {
    rotate: -10deg;
  }
  35% {
    rotate: 10deg;
  }
  40% {
    rotate: -10deg;
  }
  45% {
    rotate: 10deg;
  }
  50% {
    rotate: -10deg;
  }
  55% {
    rotate: 10deg;
  }
  60% {
    rotate: -10deg;
  }
  65% {
    rotate: 0deg;
  }
  // 70% {
  //   rotate: -10deg;
  // }
  // 75% {
  //   rotate: 10deg;
  // }
  // 80% {
  //   rotate: -10deg;
  // }
  // 85% {
  //   rotate: 10deg;
  // }
  // 90% {
  //   rotate: -10deg;
  // }
  95% {
    rotate: 0deg;
  }
  100% {
    rotate: -10deg;
  }
}
