.web-service-search-history-wrap {
  display: flex;
  flex-direction: column;
}

.web-service-search-history-card {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 0.5px solid var(--border3);
}

.web-service-search-history-keyword {
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 400;
  color: var(--text1);
  border-radius: 8px;
  height: 44px;
  padding: 0 0 0 16px;

  &:hover {
    background-color: var(--bg4);
  }
}

.web-service-search-history-keyword-link {
  flex: 1;
}

.web-service-search-history-date {
  font-size: 12px;
  font-weight: 400;
  color: var(--text3);
}

.web-service-search-history-delete-wrap {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 44px;
  height: 44px;
  border-radius: 8px;

  &:hover {
    background-color: var(--bg4);
  }
}

.web-service-search-history-delete {
  width: 16px;
  height: 16px;
}

// Tab

.web-service-searh-tab-wrap {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 16px;
  gap: 20px;
  position: fixed;
  z-index: 100;
  width: calc(100% - 135px);
  max-width: 440px;
  min-width: 304px;
  background-color: var(--bg3A95);
  box-shadow: var(--bottomSheetShadow);

  @media screen and (max-width: 510px) {
    max-width: none;
    width: 100%;
  }
}

.web-service-search-tab-btn {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 500;
  color: var(--text4);
  height: 46px;
  border-top: 3px solid rgba(0, 0, 0, 0);
  border-bottom: 3px solid rgba(0, 0, 0, 0);

  &:hover {
    color: var(--text2);
  }
}

.web-service-search-tab-btn.active {
  font-size: 14px;
  font-weight: 500;
  color: var(--text1);
  border-top: 3px solid rgba(0, 0, 0, 0);
  border-bottom: 3px solid var(--text1);
}

// Search Result Card (Contact)

.web-service-search-result-groups-wrap {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 58px 0 12px 0;
}

.web-service-search-result-card-contact-wrap.padding {
  padding: 0 16px;
}

.web-service-search-result-card-contact-wrap.noPadding {
  .web-service-search-result-card-contact {
    padding: 0 16px;
  }
}

.web-service-search-result-card-contact-wrap {
  cursor: pointer;
  width: 100%;

  &:hover {
    .web-service-search-result-card-contact-add-icon {
      background-color: var(--bg5);
    }
  }
}

.web-service-search-result-card-contact-wrap.fold {
  &:hover {
    background-color: var(--bg4);
  }
}

.web-service-search-result-card-contact-wrap.hover {
  &:hover {
    background-color: var(--bg4);
  }
}

.web-service-search-result-card-contact {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  border-bottom: 0.5px solid var(--border3);
}

.web-service-search-result-card-contact.first {
  border-top: 0.5px solid var(--border3);
}

.web-service-search-result-card-contact-body {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
  height: 64px;
  width: 100%;
}

.web-service-search-result-card-contact-info-wrap {
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 8px;
}

.web-service-search-result-card-contact-name-wrap {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.web-service-search-result-card-contact-name.equal {
  font-size: 14px;
  font-weight: 500;
  color: var(--primary1);
}

.web-service-search-result-card-contact-name {
  font-size: 14px;
  font-weight: 400;
  color: var(--text1);
}

.web-service-search-result-card-contact-number-wrap {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.web-service-search-result-card-contact-number {
  font-size: 14px;
  font-weight: 400;
  color: var(--text3);
}

.web-service-search-result-card-contact-number.equal {
  font-size: 14px;
  font-weight: 500;
  color: var(--primary1);
}

.web-service-search-group-short {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
}

.web-service-search-group-all {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
}

.web-service-search-result-group-short-seeAll {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
  width: 100%;
  font-size: 14px;
  font-weight: 500;
  color: var(--primary1);
  border-bottom: 0.5px solid var(--border3);

  &:hover {
    background-color: var(--bg4);
  }
}

.web-service-search-contact-result-wrap {
  padding: 12px 0;
}

.web-service-search-result-card-contact-add-icon {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2px;
  padding: 6px 10px 6px 8px;
  border-radius: 12px;
  background-color: var(--bg4);
  font-size: 13px;
  font-weight: 500;
  color: var(--text2);

  img {
    width: 12px;
    height: 12px;
  }
}

// Search Result Card (Call History)

.web-service-search-result-card-call-history {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
  padding: 12px 16px;
  width: 100%;
}

.web-service-search-result-card-call-history-wrap {
  cursor: pointer;
  width: 100%;
  border-bottom: 0.5px solid var(--border3);

  &:hover {
    background-color: var(--bg4);
  }
}

.web-service-search-result-card-call-history-wrap.active {
  cursor: pointer;
  width: 100%;
  border-bottom: 0.5px solid var(--border3);

  &:hover {
    background-color: var(--bg3);
  }
}

.web-service-search-result-card-call-history-icon {
  width: 32px;
}

.web-service-search-result-card-call-history-info-wrap {
  display: flex;
  flex-direction: column;
  flex: 1 1 0;
  gap: 8px;
}

.web-service-search-result-card-call-history-name-wrap {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.web-service-search-result-card-call-history-equal-name {
  font-size: 14px;
  font-weight: 500;
  color: var(--primary1);
}

.web-service-search-result-card-call-history-name {
  font-size: 14px;
  font-weight: 400;
  color: var(--text1);
}

.web-service-search-result-card-call-history-number-wrap {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.web-service-search-result-card-call-history-equal-number {
  font-size: 14px;
  font-weight: 500;
  color: var(--primary1);
}

.web-service-search-result-card-call-history-number {
  font-size: 14px;
  font-weight: 400;
  color: var(--text1);
}

.web-service-search-result-card-call-history-time {
  font-size: 12px;
  font-weight: 400;
  color: var(--text3);
}

.web-service-search-result-card-call-history-log {
  font-size: 12px;
  font-weight: 400;
  color: var(--text3);
}

.web-service-search-result-card-call-history-active-area {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 0;
  padding: 0;
  max-width: 400px;

  @media screen and (max-width: 374px) {
    padding: 0;
  }
}

.web-service-search-result-card-call-history-active-area.active {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 67px;
  padding-left: 60px;
  padding-bottom: 12px;
  padding-right: 16px;
  max-width: 400px;

  @media screen and (max-width: 374px) {
    padding: 0 16px;
  }
}

.web-service-search-result-card-call-history-button-wrap {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;

  &:hover {
    .web-service-search-result-card-call-history-active-icon-wrap {
      background-color: var(--bg5);
    }
  }
}

.web-service-search-result-card-call-history-active-icon-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 20px;
  background-color: var(--bg4);
}

.web-service-search-result-card-call-history-active-icon {
  width: 24px;
}

.web-service-search-result-card-call-history-active-icon-name {
  font-size: 11px;
  font-weight: 400;
  color: var(--text2);
}

// Group Call (selected user)

.web-service-invite-selected-user {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 32px;
  width: fit-content;
  border-radius: 16px;
  padding: 0 2px 0 8px;
  background-color: var(--bg4);
}

.web-service-invite-selected-user-profile-group {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 6px;
}

.web-service-invite-selected-user-profile-wrap {
  width: 20px;
  height: 20px;
}

.web-service-invite-selected-user-profile {
  width: 20px;
  height: 20px;
  border-radius: 10px;
  object-fit: cover;
}

.web-service-invite-selected-user-name {
  font-size: 12px;
  font-weight: 400;
  color: var(--text1);
}

.web-service-invite-selected-user-delete-wrap {
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 32px;

  .web-service-invite-selected-user-delete.default {
    opacity: 1;
  }

  .web-service-invite-selected-user-delete.hover-effect {
    opacity: 0;
  }

  &:hover {
    .web-service-invite-selected-user-delete.default {
      opacity: 0.3;
    }

    .web-service-invite-selected-user-delete.hover-effect {
      opacity: 0.7;
    }
  }
}

.web-service-invite-selected-user-delete {
  position: absolute;
  width: 24px;
  height: 24px;
}

// Group Call (selected group)

.web-service-invite-selected-group {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 9px;
  width: 100%;
  max-width: 440px;
  min-width: 304px;
  padding: 8px 16px;
  background-color: var(--bg3A95);

  @media screen and (max-width: 510px) {
    width: 100%;
    max-width: none;
  }
}

.web-service-invite-search-input-wrap {
  padding: 10px 16px;
}

// Group Call (add button)

.web-service-invite-search-add-button-wrap {
  padding: 0 16px;

  &:hover {
    background-color: var(--bg4);

    .web-service-invite-search-add-button-img-wrap {
      background-color: var(--bg5);
    }
  }
}

.web-service-invite-search-add-button {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 64px;
  width: 100%;
  gap: 12px;
  border-bottom: 0.5px solid var(--border3);
}

.web-service-invite-search-add-button-img-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  border-radius: 18px;
  background-color: var(--bg4);
}

.web-service-invite-search-add-button-img {
  width: 24px;
}

.web-service-invite-search-add-button-name {
  font-size: 15px;
  font-weight: 500;
  color: var(--text1);
}
