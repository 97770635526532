.web-service-sms-list-card {
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 12px 16px;
  height: 77px;
  width: 100%;

  &:hover {
    background-color: var(--bg4);
  }
}

.web-service-sms-list-card.underline {
  border-bottom: 0.5px solid var(--border3);
}

.web-service-sms-list-card-profile {
  width: 53px;
  height: 53px;
  border-radius: 23.5px;
}

.web-service-sms-list-main-field {
  display: flex;
  flex-direction: column;
  flex: 1 1 0;
  gap: 4px;
}

.web-service-sms-list-name {
  font-size: 15px;
  font-weight: 500;
  color: var(--text1);
}

.web-service-sms-list-text {
  font-size: 13px;
  font-weight: 400;
  line-height: 1.33;
  color: var(--text3);
  max-height: 35px;
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-word;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
}

.web-service-sms-list-card-sub-field {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 8px;
  width: 55px;
  height: 39px;
}

.web-service-sms-list-date {
  font-size: 12px;
  font-weight: 400;
  color: var(--text4);
}

.web-service-sms-list-alarm {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--areaRed);
  font-size: 11px;
  font-weight: 500;
  color: var(--white);
  padding: 4px 6px;
  border-radius: 9px;
  width: fit-content;
}

// Select Receiver

.web-service-sms-select-receiver-search-input-wrap {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 56px;
  gap: 16px;
  padding: 0 4px 0 16px;
  border-bottom: 0.5px solid var(--border3);
  border-top: 0.5px solid var(--border3);
}

.web-service-sms-select-receiver-search-input-label {
  font-size: 16px;
  font-weight: 400;
  color: var(--text2);
}

.web-service-sms-select-receiver-search-input {
  display: flex;
  flex: 1 1 0;
  font-size: 16px;
  font-weight: 500;
  color: var(--text1);
}

.web-service-sms-select-unknown-receiver {
  cursor: pointer;
  padding: 0 16px;
  max-height: 0;
  overflow: hidden;

  &:hover {
    background-color: var(--bg4);

    .web-service-sms-select-unknown-receiver-icon-wrap {
      background-color: var(--bg5);
    }
  }
}

.web-service-sms-select-unknown-receiver.active {
  max-height: 64px;
}

.web-service-sms-select-unknown-receiver-wrap {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
  height: 64px;
  border-bottom: 0.5px solid var(--border3);
}

.web-service-sms-select-unknown-receiver-icon-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  border-radius: 18px;
  background-color: var(--bg4);
}

.web-service-sms-select-unknown-receiver-icon {
  width: 24px;
  height: 24px;
}

.web-service-sms-select-unknown-receiver-text {
  font-size: 15px;
  font-weight: 500;
  color: var(--text1);
}
