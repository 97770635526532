.web-service-select-header-container {
  position: fixed;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 8px 0 16px;
  height: 54px;
  width: calc(100% - 135px);
  max-width: 440px;
  min-width: 304px;
  background-color: var(--bg3A95);
  z-index: 110;

  @media screen and (max-width: 510px) {
    max-width: none;
    width: 100%;
  }
}

.web-service-select-header-checkbox-wrap {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  height: 54px;
}

.web-service-header-text-button {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 44px;
  height: 44px;
  padding: 0;
  border-radius: 8px;
  color: var(--text1);

  &:hover {
    background-color: var(--bg4);
  }
}

// Show Image

.web-service-show-img-header-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
}

.web-service-show-img-header-title {
  font-size: 14px;
  font-weight: 500;
  color: var(--text1);
}

.web-service-show-img-header-info {
  font-size: 12px;
  font-weight: 400;
  color: var(--text3);
}
