.web-service-more-subtitle {
  display: flex;
  align-items: center;
  height: 54px;
  padding: 0 16px;
  font-size: 14px;
  font-weight: 700;
  color: var(--text2);
}

.web-service-more-card {
  cursor: pointer;
  width: 100%;
  padding: 0 16px;
  background-color: var(--bg3);

  &:hover {
    background-color: var(--bg35);
  }
}

.web-service-more-card.hoverDisable {
  pointer-events: none;
}

.web-service-more-card-left {
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: flex-start;
  gap: 12px;
}
.web-service-more-card-inner.border {
  border-bottom: 0.5px solid var(--border3);
}

.web-service-more-card-name {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 400;
  color: var(--text1);
}

.web-service-more-card-right-wrap {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
}

.web-service-more-card-right-text {
  position: relative;
  font-size: 16px;
  font-weight: 400;
  color: var(--primary1);
}

.web-service-more-card-right-text.inactive {
  font-size: 16px;
  font-weight: 400;
  color: var(--text4);
}

.web-service-more-card-right-text.text1 {
  font-size: 16px;
  font-weight: 400;
  color: var(--text1);
}

.web-service-more-card-right-arrow {
  width: 20px;
  height: 20px;
}

.web-service-more-card-right-text-alarm {
  position: absolute;
  right: -8px;
  top: -2px;
  width: 6px;
  height: 6px;
  border-radius: 3px;
  background-color: var(--areaRed);
}

.web-service-more-card-right-alarm-amount {
  font-size: 12px;
  font-weight: 500;
  color: var(--white);
  padding: 4px 6px;
  border-radius: 10px;
  background-color: var(--areaRed);
}

.web-service-more-card-player {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--bg4);
  width: 36px;
  height: 36px;
  border-radius: 18px;
}

.web-service-more-card-player-img {
  width: 24px;
  height: 24px;
}

.web-service-more-card-body {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 56px;
}

.web-service-more-card-inner-text {
  padding: 0 0 12px 0;
}

.web-service-more-card-inner-text-ellipsis {
  white-space: pre-line;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.66;
  color: var(--text3);
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  max-height: 58px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.web-service-more-card-outer-text {
  padding: 8px 16px 20px 16px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.33;
  color: var(--text3);
}

.web-service-more-hide {
  max-height: 0;
  overflow: hidden;
}

.web-service-more-hide.active {
  height: 100%;
}

.web-service-more-hide.active.mh200 {
  max-height: 200px;
}

.web-service-more-hide.active.mh800 {
  max-height: 800px;
}

// Type

.web-service-more-type-textarea-wrap {
  width: 100%;
  height: 100%;
  padding: 20px 16px;
}

.web-service-more-type-textarea {
  resize: none;
  width: 100%;
  height: 500px;
}

.web-service-more-type-bottom-wrap {
  position: absolute;
  bottom: 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 12px 16px;
  box-shadow: var(--bottomSheetShadow);
}

.web-service-more-type-max-length {
  font-size: 14px;
  font-weight: 400;
  color: var(--text1);
}

.web-service-more-type-img-wrap {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 36px;
  width: 36px;
  border-radius: 18px;
  background-color: var(--primary2);
}

.web-service-more-type-img {
  height: 24px;
  width: 24px;
}

.web-service-more-forwarding-info-section {
  padding-top: 36px;
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  border-top: 0.5px solid var(--border4);
}

.web-service-more-forwarding-info-wrap {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 8px;
  padding: 0 16px;
}

.web-service-more-forwarding-info-hypen {
  font-size: 14px;
  font-weight: 400;
  line-height: 1.33;
  color: var(--text3);
}

.web-service-more-forwarding-info-text {
  font-size: 14px;
  font-weight: 400;
  line-height: 1.33;
  color: var(--text3);
}

// My Profile

.web-service-my-profile-input-wrap {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 16px;
}

// Change Password

.web-service-change-password-input-wrap {
  display: flex;
  flex-direction: column;
  padding: 0 16px;
  gap: 24px;
}

.web-service-change-password-btn-wrap {
  margin-top: 16px;
}

.web-service-change-password-complete-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  padding: 48px 16px;
}

.web-service-change-password-complete-img {
  width: 48px;
  height: 48px;
}

.web-service-change-password-complete-script-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
}

.web-service-change-password-complete-btn-wrap {
  padding: 0 16px;
}

// Notice

.web-service-notice-list-card-wrap {
  cursor: pointer;
  height: 84px;
  padding: 0 16px;
  background-color: var(--bg3);

  &:hover {
    background-color: var(--bg35);
  }
}

.web-service-notice-list-card {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  height: 84px;
}

.web-service-notice-list-card.bottom-border {
  border-bottom: 0.5px solid var(--border3);
}

.web-service-notice-list-card-left {
  flex: 1 1 0;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.web-service-notice-list-card-arrow {
  width: 20px;
  height: 20px;
}

.web-service-notice-list-card-title {
  font-size: 16px;
  font-weight: 400;
  color: var(--text1);
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  height: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.web-service-notice-list-card-date {
  font-size: 14px;
  font-weight: 400;
  color: var(--text3);
}

// Notice Read

.web-service-notice-read-wrap {
  padding: 0 16px;
}

.web-service-notice-read-head {
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 24px 0;
}

.web-service-notice-read-title {
  font-size: 18px;
  font-weight: 500;
  color: var(--text1);
  line-height: 1.66;
}

.web-service-notice-read-date {
  font-size: 14px;
  font-weight: 400;
  color: var(--text3);
}

.web-service-notice-read-body {
  padding: 24px 0;
  border-top: 0.5px solid var(--border3);
}

.web-service-notice-read-content {
  font-size: 16px;
  font-weight: 400;
  color: var(--text2);
  line-height: 1.66;
}

.web-service-notice-read-foot {
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 24px 0;
  border-top: 0.5px solid var(--border3);
}

.web-service-notice-read-foot-title {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 6px;

  font-size: 14px;
  font-weight: 400;
  color: var(--text1);
}

.web-service-notice-read-foot-img {
  width: 20px;
  height: 20px;
}

.web-service-notice-read-file-container {
  overflow: hidden;
  border-radius: 8px;
}

.web-service-notice-read-file {
  cursor: pointer;

  height: 44px;
  background-color: var(--bg4);
  padding: 0 16px;

  &:hover {
    background-color: var(--bg5);
  }
}

.web-service-notice-read-file-bottom-border {
  font-size: 14px;
  font-weight: 400;
  color: var(--text2);
  line-height: 44px;

  height: 44px;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.web-service-notice-read-file-bottom-border.active {
  border-bottom: 0.5px solid var(--border4);
}

// Version

.web-service-version-alert {
  background-color: var(--areaRed);
  width: 20px;
  height: 20px;
  border-radius: 10px;

  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 14px;
  font-weight: 700;
  color: var(--white);
}

.web-service-version-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  padding-top: 24px;
}

.web-service-version-img {
  width: 70px;
  height: 70px;
}

.web-service-version-ver {
  font-size: 14px;
  font-weight: 400;
  color: var(--text1);
  padding: 6px 12px;
  border-radius: 13px;
  border: 0.5px solid var(--border4);
}

.web-service-version-text {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}

// Available Time

.web-service-more-available-time-wrap {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 8px;
  margin: 24px;
  padding: 36px 0;
  border-radius: 20px;
  border: 0.5px solid var(--border3);
}

.web-service-more-available-time-set {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.web-service-more-available-time-label {
  width: 100%;
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  color: var(--text3);
}

.web-service-more-available-time-input {
  flex: 1 1 0;
  cursor: pointer;
  padding: 12px 24px;
  background-color: var(--bg4);
  width: 84px;
  border-radius: 12px;
  text-align: center;

  font-size: 20px;
  font-weight: 500;
  color: var(--text1);

  &:hover {
    background-color: var(--bg5);
  }
  &:focus {
    background-color: var(--bg5);
    box-shadow: inset 0 0 0 2px var(--reverseLight);
  }
}

.web-service-more-available-time-dots {
  font-size: 20px;
  font-weight: 500;
  color: var(--text1);
  padding-top: 22px;
  height: 48px;
}
