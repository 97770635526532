.web-service-messenger-selected-user-group {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 12px;
  width: 100%;
  max-width: 440px;
  min-width: 304px;
  padding: 8px 16px;
  background-color: var(--bg3A95);

  @media screen and (max-width: 510px) {
    width: 100%;
    max-width: none;
  }
}

// Menu

.web-service-messenger-chat-menu {
  position: absolute;
  right: 0;
  top: 0;
  max-width: 0;
  width: 100%;
  height: calc(100vh - 48px);
  background-color: var(--bg3);

  overflow-y: auto;
}

.web-service-messenger-chat-menu-relative {
  position: relative;
  padding-bottom: 100px;
}

.web-service-messenger-chat-menu::-webkit-scrollbar {
  width: 12px;
  height: 8px;
  border-radius: 6px;
}
.web-service-messenger-chat-menu::-webkit-scrollbar-thumb {
  background: var(--bg4);
  border-radius: 8px;
  border: 2px solid transparent;
  background-clip: padding-box;

  &:hover {
    background: var(--bg5);
  }
}
.web-service-messenger-chat-menu::-webkit-scrollbar-track {
  background-color: var(--bg3);
}

.web-service-messenger-chat-menu.active {
  max-width: 280px;
}

.web-service-messenger-chat-menu-section {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.web-service-messenger-chat-menu-subtitle {
  padding: 0 20px;
}

.web-service-messenger-chat-menu-subtitle-underline {
  border-bottom: 0.5px solid var(--border3);
  height: 54px;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 14px;
  font-weight: 700;
  color: var(--text1);
}

.web-service-messenger-chat-menu-icon-wrap {
  cursor: pointer;
  padding: 0 20px;

  &:hover {
    background-color: var(--bg4);
  }
}

.web-service-messenger-chat-menu-icon-underline {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  height: 48px;
  border-bottom: 0.5px solid var(--border3);

  font-size: 14px;
  font-weight: 400;
  color: var(--text1);
}

.web-service-messenger-chat-menu-icon {
  width: 24px;
  height: 24px;
}

.web-service-messenger-chat-menu-member {
  cursor: pointer;
  padding: 0 20px;

  &:hover {
    background-color: var(--bg4);

    .web-service-messenger-chat-menu-member-invite-icon-wrap {
      background-color: var(--bg5);
    }
  }
}

.web-service-messenger-chat-menu-member-underline {
  height: 64px;
  border-bottom: 0.5px solid var(--border3);
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
  font-size: 15px;
  font-weight: 500;
  color: var(--text1);
}

.web-service-messenger-chat-menu-member-invite-icon-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  border-radius: 18px;
  background-color: var(--bg4);
}

.web-service-messenger-chat-menu-member-invite-icon {
  width: 24px;
  height: 24px;
}

.web-service-messenger-chat-menu-member-profile {
  height: 36px;
  height: 36px;
  border-radius: 18px;
}

.web-service-messenger-chat-menu-member-name-wrap {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.web-service-messenger-chat-menu-member-name {
  font-size: 15px;
  font-weight: 500;
  color: var(--text1);
  max-width: 186px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.web-service-messenger-chat-menu-member-company {
  font-size: 13px;
  font-weight: 400;
  color: var(--text3);
  max-width: 180px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.web-service-messenger-chat-menu-foot {
  display: flex;
  cursor: pointer;
  position: fixed;
  max-width: 0;
  width: 280px;
  height: 48px;
  padding: 0px;
  bottom: 0;
  flex-direction: row;
  align-items: center;
  background-color: var(--bg3);
  border-top: 0.5px solid var(--border3);
  gap: 8px;
  font-size: 14px;
  font-weight: 400;
  color: var(--text1);
  overflow: hidden;

  &:hover {
    background-color: var(--bg4);
  }
}

.web-service-messenger-chat-menu-foot.active {
  display: flex;
  cursor: pointer;
  position: fixed;
  max-width: 280px;
  width: 280px;
  height: 48px;
  padding: 0 20px;
  bottom: 0;
  flex-direction: row;
  align-items: center;
  background-color: var(--bg3);
  border-top: 0.5px solid var(--border3);
  gap: 8px;
  font-size: 14px;
  font-weight: 400;
  color: var(--text1);

  &:hover {
    background-color: var(--bg4);
  }
}

.web-service-messenger-chat-menu-out-icon {
  width: 24px;
  height: 24px;
}

// Image Storage
.web-service-messenger-img-storage-img-wrap {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 1px;
  flex-wrap: wrap;
}

// Image in Storage

.web-service-messenger-img-in-storage {
  position: relative;
  cursor: pointer;
  display: flex;
  flex-shrink: 0;
  width: calc((100% - 2px) / 3);

  &:hover {
    .web-service-messenger-img-in-storage-overlay-hover {
      opacity: 1;
    }
  }
}

.web-service-messenger-img-in-storage-img {
  width: 100%;
  object-fit: cover;
}

.web-service-messenger-img-in-storage-overlay-hover {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: var(--blackA30);
  opacity: 0;
}

.web-service-messenger-img-in-storage-overlay {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
}

.web-service-messenger-img-in-storage-overlay.video {
  background: linear-gradient(rgba(0, 0, 0, 0), var(--blackA50));
}

.web-service-messenger-img-in-storage-overlay.selectMode {
  background: linear-gradient(var(--blackA30), rgba(0, 0, 0, 0));
}

.web-service-messenger-img-in-storage-overlay.selectMode.video {
  background: linear-gradient(
    var(--blackA30),
    rgba(0, 0, 0, 0),
    var(--blackA50)
  );
}

.web-service-messenger-img-in-storage-overlay-checkbox {
  padding: 1px 12px;
}

.web-service-messenger-img-in-storage-overlay-video-time {
  padding: 12px;
  font-size: 12px;
  font-weight: 400;
  color: var(--white);
}

// Show Img

.web-service-show-img-wrap-fixed {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 120;
}

.web-service-show-img-wrap-relative {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: var(--black);
}

.web-service-show-img-wrap {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  overflow-y: auto;
  width: 100%;
  height: 100%;
}

.web-service-show-img-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  z-index: 122;
}

.web-service-show-img-menu-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background-color: var(--blackA30);
  padding: 0;
}

.web-service-show-img-button-set-wrap {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.web-service-show-img-button-set-wrap {
  @media screen and (max-width: 575px) {
    width: 54px;
    height: 54px;
  }
}

.web-service-show-img-button-wrap {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 64px;
  height: 64px;

  &:hover {
    background-color: #222;
  }

  @media screen and (max-width: 575px) {
    width: 54px;
    height: 54px;
  }
}

.web-service-show-img-button-wrap.top {
  display: flex;
  @media screen and (max-width: 575px) {
    display: none;
  }
}

.web-service-show-img-button-wrap.bottom {
  display: flex;
  @media screen and (min-width: 576px) {
    display: none;
  }
}

.web-service-show-img-button-wrap.delete {
  &:hover {
    background-color: var(--areaRed);
  }
}

.web-service-show-img-button-area {
  width: 64px;
  height: 64px;

  @media screen and (max-width: 575px) {
    display: none;
  }
}

.web-service-show-img-icon {
  width: 32px;
  height: 32px;
}

.web-service-show-img-info-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  @media screen and (max-width: 575px) {
    gap: 6px;
  }
}

.web-service-show-img-title {
  font-size: 16px;
  font-weight: 500;
  color: var(--white);

  @media screen and (max-width: 575px) {
    font-size: 14px;
  }
}

.web-service-show-img-info {
  font-size: 14px;
  font-weight: 300;
  color: var(--white);

  @media screen and (max-width: 575px) {
    font-size: 12px;
  }
}

.web-service-img {
  display: none;
}

.web-service-img.width.display {
  display: block;
  width: 100%;
  max-width: 1000px;
}

.web-service-img.height.display {
  display: block;
  width: 100%;
  max-height: 1000px;
  object-fit: contain;
}

.web-service-show-img-arrow-wrap {
  width: 64px;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:hover {
    background-color: #222;
  }
}

.web-service-show-img-bottom {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 12px;
  height: 64px;
  background-color: var(--blackA30);
}

.web-service-show-img-arrow {
  width: 36px;
  height: 36px;
}

.web-service-show-img-arrow.left {
  margin-right: 2px;
}

.web-service-show-img-arrow.right {
  margin-left: 2px;
}

.web-service-show-img-index {
  font-size: 14px;
  font-weight: 500;
  color: var(--white);
}

// File in Storage

.web-service-messenger-file-in-storage {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
  height: 72px;
  padding: 0 16px;

  &:hover {
    background-color: var(--bg4);

    .web-service-messenger-file-in-storage-icon-wrap {
      border: 0.5px solid var(--border4);
    }
  }
}

.web-service-messenger-file-in-storage-icon-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 48px;
  height: 48px;
  border-radius: 18px;
  border: 0.5px solid var(--border3);
}

.web-service-messenger-file-in-storage-icon {
  width: 32px;
  height: 32px;
}

.web-service-messenger-file-in-storage-text-wrap {
  display: flex;
  flex-direction: column;
  flex: 1 1 0;
  gap: 6px;
  overflow: hidden;
}

.web-service-messenger-file-in-storage-name {
  font-size: 16px;
  font-weight: 400;
  color: var(--text1);
  height: 18px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.web-service-messenger-file-in-storage-subinfo {
  font-size: 12px;
  font-weight: 400;
  color: var(--text3);
  overflow: hidden;
  text-overflow: ellipsis;
}

// Change Name

.web-service-messenger-chat-change-name-wrap {
  padding: 0 16px;
}

// Toast

.web-service-messenger-chat-toast-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  width: 100%;
  left: 0;
  bottom: 86px;

  @media screen and (min-width: 511px) {
    padding-left: 135px;
  }
}

.web-service-messenger-chat-toast {
  padding: 14px 28px;
  border-radius: 21px;
  background-color: var(--reverseLight);

  font-size: 14px;
  font-weight: 400;
  color: var(--reverseDark);
}
