.web-service-no-result-wrap {
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
  padding: 36px 16px;
}

.web-service-no-result-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 12px;
}

.web-service-no-result-img-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 64px;
  height: 64px;
  border-radius: 32px;
  background-color: var(--bg4);
}

.web-service-no-result-img {
  width: 32px;
}

.web-service-no-result-text {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.6;
  text-align: center;
  color: var(--text2);
  white-space: pre-line;
}
