h1 {
  font-size: 48px;
  font-weight: 700;
  color: var(--text1);
}

h2 {
  font-size: 36px;
  font-weight: 700;
  color: var(--text1);
}

p {
  font-size: 20px;
  font-weight: 600;
  color: var(--text2);
}

.trs-015 {
  transition: 0.15s var(--normal-transition);
}

.fade-in-1 {
  animation: fadeIn 1.5s var(--normal-transition) 0s 1 alternate forwards;
}

.am-pause {
  animation-play-state: paused;
}

.am-running {
  animation-play-state: running;
}

@keyframes fadeIn {
  from,
  0% {
    opacity: 0;
    margin-top: 40px;
  }
  100% {
    opacity: 1;
    margin-top: 0px;
  }
}

.landing-main-text-wrap {
  display: flex;
  flex-direction: column;
  gap: 36px;
  height: 92px;
}

.landing-container {
  background-color: var(--bg3);
  min-height: 100vh;
  padding: 0;
}

.landing-contents-container {
  margin: 0;
  padding: 56px 0 100px 0;
}

.landing-main-section-1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 48px;
  background: var(--bg3);
  padding-top: 100px;
  height: 100vh;
  overflow: hidden;

  .img-insec-01 {
    width: 720px;
  }
}

.landing-main-section-2 {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 48px;
  background: var(--bg4);
  padding-top: 200px;

  h2 {
    text-align: center;
  }
}

.landing-main-section-2-img-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.landing-main-section-2-img-wrap {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: fit-content;

  .img-insec-01 {
    position: absolute;
    width: 480px;
    left: -80px;
    bottom: 60px;
    z-index: 12;
  }

  .img-insec-02 {
    width: 1280px;
    z-index: 11;
  }
}
