.landing-nav-container {
  background-color: var(--bg3A95);
  position: fixed;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 64px;
  padding: 0 48px;
  z-index: 101;
}

.landing-nav-logo {
  img {
    height: 32px;
  }
}

.landing-nav-menu-wrap {
  display: flex;
  flex-direction: row;
  gap: 24px;
}

.landing-nav-menu-btn {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 6px;
  height: 48px;
  padding: 6px 12px 0 12px;
  color: var(--text2);

  .landing-nav-menu-btn-underline {
    width: 0;
    height: 1px;
    border-bottom: 1px solid var(--bg2);
  }

  &:hover {
    color: var(--reverseLight);

    .landing-nav-menu-btn-underline {
      width: 100%;
      border-bottom: 1px solid var(--reverseLight);
    }
  }
}
