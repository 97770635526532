.web-service-contact-group-container {
  cursor: pointer;
  padding: 0 16px;

  &:hover {
    background-color: var(--bg4);
  }
}

.web-service-contact-group {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 0.5px solid var(--border3);
  gap: 12px;
  height: 64px;
}

.web-service-contact-group.first {
  border-top: 0.5px solid var(--border3);
}

.web-service-contact-group-icon-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  border-radius: 18px;
  overflow: hidden;
  background-color: var(--primary2A20);
}

.web-service-contact-group-icon-text-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  border-radius: 18px;
  overflow: hidden;
  background-color: var(--primary2);
  font-size: 15px;
  font-weight: 700;
  color: var(--white);
}

.web-service-contact-group-icon {
  width: 28px;
  height: 28px;
}

.web-service-contact-group-name-wrap {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1 1 0;
}

.web-service-contact-group-name {
  font-size: 15px;
  font-weight: 500;
  color: var(--text1);
  flex: 1 1 0;
}

.web-service-contact-group-equal-name {
  font-size: 15px;
  font-weight: 500;
  color: var(--primary1);
}

.web-service-contact-group-arrow {
  width: 20;
  height: 20px;
}

.web-service-contact-bookmark-wrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 12px;
  margin-top: 24px;
}

.web-service-contact-bookmark-title {
  padding: 0 0 0 16px;
  font-size: 14px;
  font-weight: 700;
  color: var(--text1);
}

.web-service-search-result-card-contact-spreadarea {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-top: 0;
  padding-right: 0;
  padding-bottom: 0;
  height: 0;
  overflow: hidden;

  .web-service-search-result-card-call-history-button-wrap {
    opacity: 0;
  }
}

.web-service-search-result-card-contact-spreadarea.spread {
  gap: 16px;
  padding-left: 48px;
  padding-right: 150px;
  padding-bottom: 16px;
  height: 75px;

  .web-service-search-result-card-call-history-button-wrap {
    opacity: 1;
  }
}

.web-service-contact-folder-tab {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px 16px;
  overflow-x: auto;
  overflow-y: hidden;
}
.web-service-contact-folder-tab::-webkit-scrollbar {
  width: 16px;
  height: 10px;
  border-radius: 6px;
}
.web-service-contact-folder-tab::-webkit-scrollbar-thumb {
  background: var(--bg4);
  border-radius: 8px;
  border: 1px solid transparent;
  background-clip: padding-box;

  &:hover {
    background: var(--bg5);
  }
}
.web-service-contact-folder-tab::-webkit-scrollbar-track {
  background-color: var(--bg3);
}

.web-service-contact-folder-tab-name {
  display: flex;
  flex-shrink: 0;
  cursor: pointer;
  padding: 10px 14px;
  background-color: var(--bg4);
  border-radius: 12px;
  font-size: 14px;
  font-weight: 400;
  color: var(--text1);

  &:hover {
    background-color: var(--bg5);
  }
}

.web-service-contact-folder-tab-arrow-wrap {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  width: 34px;
  height: 34px;
}

.web-service-contact-folder-tab-arrow {
  width: 20px;
  height: 20px;
}

// Add Contact

.web-service-add-contact-body-wrap {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 0 16px;
}

.web-service-add-contact-profile-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 24px 0;
}

.web-service-add-contact-profile-img-wrap {
  cursor: pointer;
  position: relative;
  width: 96px;
  height: 96px;
  border-radius: 48px;

  &:hover {
    .web-service-add-contact-edit-wrap {
      background-color: var(--primary2A60);
    }
  }
}

.web-service-add-contact-profile-img {
  width: 96px;
  height: 96px;
  border-radius: 48px;
  object-fit: cover;
}

.web-service-add-contact-edit-wrap {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 0;
  bottom: 0;
  width: 36px;
  height: 36px;
  border-radius: 18px;
  background-color: var(--primary2);
}

.web-service-add-contact-edit-icon {
  width: 24px;
  height: 24px;
}

.web-service-contact-add-all-input-wrap {
  padding: 10px 16px;
}

// Camera

.web-service-contact-camera-view {
  width: 100%;
  height: 80vh;
  max-height: 512px;
  background: url('../../../public/img/photo/photo1.jpg') center center / cover
    no-repeat;
}

.web-service-contact-camera-button-area {
  width: 100%;
  padding: 36px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.web-service-contact-camera-button-outline {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 72px;
  height: 72px;
  border-radius: 36px;
  border: 2px solid var(--primary2);

  &:hover {
    border: 2px solid var(--primary2A60);

    .web-service-contact-camera-button {
      background-color: var(--primary2A60);
    }
  }
}

.web-service-contact-camera-button {
  width: 56px;
  height: 56px;
  border-radius: 28px;
  background-color: var(--primary2);
}

// Profile

.web-service-contact-profile-head {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  padding: 24px 0;
}

.web-service-contact-profile-img {
  width: 96px;
  height: 96px;
  border-radius: 48px;
}

.web-service-contact-profile-name-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
}

.web-service-contact-profile-name {
  font-size: 20px;
  font-weight: 700;
  color: var(--text1);
}

.web-service-contact-profile-subinfo {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
}

.web-service-contact-profile-position {
  font-size: 14px;
  font-weight: 400;
  color: var(--text1);
}

.web-service-contact-profile-subinfo-dot {
  font-size: 14px;
  font-weight: 400;
  color: var(--text1);
}

.web-service-contact-profile-company {
  font-size: 14px;
  font-weight: 400;
  color: var(--text1);
}

.web-service-contact-profile-state.my {
  cursor: pointer;
  padding: 12px 16px;
  border-radius: 20px;
  background-color: var(--bg4);
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;

  font-size: 14px;
  font-weight: 500;
  color: var(--text1);

  img {
    width: 16px;
  }

  div {
    width: 12px;
    height: 12px;
    border-radius: 8px;
    background-color: var(--primary2);
  }

  &:hover {
    background-color: var(--bg5);
  }
}

.web-service-contact-profile-state.other {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;

  font-size: 14px;
  font-weight: 500;
  color: var(--text1);

  div {
    width: 12px;
    height: 12px;
    border-radius: 8px;
    background-color: var(--primary2);
  }
}

.web-service-contact-profile-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
}

.web-service-contact-profile-button-section {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 0 16px;
  gap: 16px;
}

.web-service-contact-profile-button {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 8px;
  font-size: 16px;
  font-weight: 500;
  color: var(--primary1);
  background-color: var(--bg2);
  height: 48px;
  width: 48px;
  border-radius: 24px;

  &:hover {
    background-color: var(--bg4);
  }
}

.web-service-contact-profile-button-icon {
  width: 24px;
  height: 24px;
}

.web-service-contact-profile-number-section {
  width: 100%;
  padding: 0 20px;
}

.web-service-contact-profile-number-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 12px;
  background-color: var(--bg2);
}

.web-service-contact-profile-number-wrap {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 52px;
  padding: 0 20px;
}

.web-service-contact-profile-number-name {
  font-size: 16px;
  font-weight: 400;
  color: var(--text1);
}

.web-service-contact-profile-number {
  cursor: pointer;
  font-size: 16px;
  font-weight: 400;
  color: var(--primary1);
  line-height: 52px;

  &:hover {
    text-decoration: underline;
  }
}

.web-service-contact-profile-number-border {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 52px;
  width: 100%;
  border-bottom: 0.5px solid var(--border2);
}

.web-service-contact-profile-memo-section {
  padding: 0 20px;
}

.web-service-contact-profile-memo-wrap {
  padding: 14px 20px 20px 20px;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  background-color: var(--bg2);
}

.web-service-contact-profile-memo-title-wrap {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.web-service-contact-profile-title {
  font-size: 16px;
  font-weight: 700;
  color: var(--text1);
}

.web-service-contact-profile-memo-add {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 12px 8px 10px;
  background-color: var(--bg3);
  border-radius: 12px;
  font-size: 13px;
  font-weight: 500;
  color: var(--text2);

  &:hover {
    background-color: var(--bg4);
  }
}

.web-service-contact-profile-memo-add-icon {
  width: 12px;
  height: 12px;
}

.web-service-contact-profile-memo-info {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 8px;
  background-color: var(--bg3);
  padding: 20px 20px 16px 20px;
  border-radius: 12px;

  &:hover {
    background-color: var(--bg4);
  }
}

.web-service-contact-profile-memo-time {
  font-size: 14px;
  font-weight: 400;
  color: var(--text3);
}

.web-service-contact-profile-memo-text {
  font-size: 16px;
  font-weight: 400;
  color: var(--text2);
  line-height: 1.33;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.web-service-contact-profile-call-log-section {
  padding: 0 20px;
  width: 100%;
}

.web-service-contact-profile-call-log-container {
  display: flex;
  flex-direction: column;
  padding: 20px;
  background-color: var(--bg2);
  border-radius: 12px;
}

.web-service-contact-profile-call-log {
  cursor: pointer;
  width: 100%;
  height: 60px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-bottom: 0.5px solid var(--border2);

  &:hover {
    .web-service-contact-profile-call-log-record-wrap {
      background-color: var(--bg4);
    }
  }
}

.web-service-contact-profile-call-log-icon {
  width: 28px;
  height: 28px;
}

.web-service-contact-profile-call-log-time {
  font-size: 16px;
  font-weight: 400;
  color: var(--text1);
}

.web-service-contact-profile-call-log-record-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  border-radius: 18px;
  background-color: var(--bg3);
}

.web-service-contact-profile-call-log-record-icon {
  width: 22px;
  height: 22px;
}

.web-service-contact-profile-call-log-left {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
}

.web-service-contact-profile-date {
  font-size: 16px;
  font-weight: 400;
  margin: 32px 0 0 0;
  padding-bottom: 16px;
  border-bottom: 0.5px solid var(--border2);
}
