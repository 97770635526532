.web-service-nav-container.bottom-nav {
  display: none;
  flex-shrink: 0;
  z-index: 110;

  @media screen and (max-width: 510px) {
    position: fixed;
    display: flex;
    width: 100%;
    background-color: var(--bg3);
    bottom: 0;
    padding: 12px 0 12px 0;
    flex-direction: row;
    box-shadow: var(--bottomSheetShadow);
    height: fit-content;
  }
}

.web-service-nav-container.side-nav {
  position: fixed;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  width: 135px;
  height: 100vh;
  background-color: var(--bg4);
  padding-top: 36px;
  border-right: 0.5px solid var(--border4);

  @media screen and (max-width: 510px) {
    display: none;
  }
}

.web-service-nav-btn-set {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
  height: 52px;
  padding: 0 20px;

  @media screen and (max-width: 510px) {
    flex-direction: column;
    flex: 1 1 0;
    gap: 4px;
    height: fit-content;
    padding: 0;
  }

  &:hover {
    .web-service-nav-btn-icon {
      opacity: 0;
    }

    .web-service-nav-btn-icon.active {
      opacity: 0.7;
    }

    .web-service-nav-btn-text-wrap {
      color: var(--text2);
    }
  }
}

.web-service-nav-btn-set.active {
  .web-service-nav-btn-icon {
    opacity: 0;
  }

  .web-service-nav-btn-icon.active {
    opacity: 1;
  }

  .web-service-nav-btn-text-wrap {
    color: var(--text1);
  }
}

.web-service-nav-btn-icon-wrap {
  position: relative;
  width: 24px;
  height: 24px;

  @media screen and (max-width: 510px) {
    width: 32px;
    height: 32px;
  }
}

.web-service-nav-btn-icon {
  position: absolute;
  width: 24px;
  height: 24px;

  @media screen and (max-width: 510px) {
    width: 32px;
    height: 32px;
  }
}

.web-service-nav-btn-icon.active {
  position: absolute;
  opacity: 0;
}

.web-service-nav-btn-text-wrap {
  font-size: 14px;
  font-weight: 500;
  color: var(--text3);

  @media screen and (max-width: 510px) {
    font-size: 11px;
    font-weight: 400;
  }
}

.web-service-nav-btn-text-wrap.active {
  color: var(--text1);
}

body.light #web-service-nav-dial .web-service-nav-btn-icon {
  background: url('../../../public/icon/lightmode/bottom_navigation/dial-inactive.png')
    center center / cover;
}

body.light #web-service-nav-dial .web-service-nav-btn-icon.active {
  background: url('../../../public/icon/lightmode/bottom_navigation/dial-active.png')
    center center / cover;
}

body.light #web-service-nav-call-log .web-service-nav-btn-icon {
  background: url('../../../public/icon/lightmode/bottom_navigation/call-log-inactive.png')
    center center / cover;
}

body.light #web-service-nav-call-log .web-service-nav-btn-icon.active {
  background: url('../../../public/icon/lightmode/bottom_navigation/call-log-active.png')
    center center / cover;
}

body.light #web-service-nav-sms .web-service-nav-btn-icon {
  background: url('../../../public/icon/lightmode/bottom_navigation/sms-inactive.png')
    center center / cover;
}

body.light #web-service-nav-sms .web-service-nav-btn-icon.active {
  background: url('../../../public/icon/lightmode/bottom_navigation/sms-active.png')
    center center / cover;
}

body.light #web-service-nav-messenger .web-service-nav-btn-icon {
  background: url('../../../public/icon/lightmode/bottom_navigation/messenger-inactive.png')
    center center / cover;
}

body.light #web-service-nav-messenger .web-service-nav-btn-icon.active {
  background: url('../../../public/icon/lightmode/bottom_navigation/messenger-active.png')
    center center / cover;
}

body.light #web-service-nav-contact .web-service-nav-btn-icon {
  background: url('../../../public/icon/lightmode/bottom_navigation/contact-inactive.png')
    center center / cover;
}

body.light #web-service-nav-contact .web-service-nav-btn-icon.active {
  background: url('../../../public/icon/lightmode/bottom_navigation/contact-active.png')
    center center / cover;
}

body.light #web-service-nav-more .web-service-nav-btn-icon {
  background: url('../../../public/icon/lightmode/bottom_navigation/more-inactive.png')
    center center / cover;
}

body.light #web-service-nav-more .web-service-nav-btn-icon.active {
  background: url('../../../public/icon/lightmode/bottom_navigation/more-active.png')
    center center / cover;
}

body.dark #web-service-nav-dial .web-service-nav-btn-icon {
  background: url('../../../public/icon/darkmode/bottom_navigation/dial-inactive.png')
    center center / cover;
}

body.dark #web-service-nav-dial .web-service-nav-btn-icon.active {
  background: url('../../../public/icon/darkmode/bottom_navigation/dial-active.png')
    center center / cover;
}

body.dark #web-service-nav-call-log .web-service-nav-btn-icon {
  background: url('../../../public/icon/darkmode/bottom_navigation/call-log-inactive.png')
    center center / cover;
}

body.dark #web-service-nav-call-log .web-service-nav-btn-icon.active {
  background: url('../../../public/icon/darkmode/bottom_navigation/call-log-active.png')
    center center / cover;
}

body.dark #web-service-nav-sms .web-service-nav-btn-icon {
  background: url('../../../public/icon/darkmode/bottom_navigation/sms-inactive.png')
    center center / cover;
}

body.dark #web-service-nav-sms .web-service-nav-btn-icon.active {
  background: url('../../../public/icon/darkmode/bottom_navigation/sms-active.png')
    center center / cover;
}

body.dark #web-service-nav-messenger .web-service-nav-btn-icon {
  background: url('../../../public/icon/darkmode/bottom_navigation/messenger-inactive.png')
    center center / cover;
}

body.dark #web-service-nav-messenger .web-service-nav-btn-icon.active {
  background: url('../../../public/icon/darkmode/bottom_navigation/messenger-active.png')
    center center / cover;
}

body.dark #web-service-nav-contact .web-service-nav-btn-icon {
  background: url('../../../public/icon/darkmode/bottom_navigation/contact-inactive.png')
    center center / cover;
}

body.dark #web-service-nav-contact .web-service-nav-btn-icon.active {
  background: url('../../../public/icon/darkmode/bottom_navigation/contact-active.png')
    center center / cover;
}

body.dark #web-service-nav-more .web-service-nav-btn-icon {
  background: url('../../../public/icon/darkmode/bottom_navigation/more-inactive.png')
    center center / cover;
}

body.dark #web-service-nav-more .web-service-nav-btn-icon.active {
  background: url('../../../public/icon/darkmode/bottom_navigation/more-active.png')
    center center / cover;
}

.web-service-center-title-header-right-submit {
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  color: var(--primary1);
  width: 44px;
  height: 44px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: var(--bg4);
  }
}

.web-service-multi-right-header-right-button-wrap {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 44px;
  border-radius: 8px;
  padding: 0 8px;

  &:hover {
    background-color: var(--bg4);
  }
}

.web-service-multi-right-header-container {
  padding: 0 8px 0 4px;
  position: fixed;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 54px;
  width: calc(100% - 135px);
  max-width: 440px;
  min-width: 304px;
  background-color: var(--bg3A95);
  z-index: 110;

  @media screen and (max-width: 510px) {
    max-width: none;
    width: 100%;
  }
}

.web-service-multi-right-header-left {
  cursor: pointer;
  width: 44px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;

  &:hover {
    background-color: var(--bg4);
  }
}

.web-service-multi-right-left-icon {
  width: 32px;
  height: 32px;
}

.web-service-multi-right-title-header-right {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.web-service-multi-right-header-right-button-wrap {
  font-size: 16px;
  font-weight: 400;
  color: var(--text1);
}

.web-service-multi-right-header-right-button-wrap.disabled {
  font-size: 16px;
  font-weight: 400;
  color: var(--text4);
}

.web-service-profile-header-container {
  padding: 0 8px 0 4px;
  position: fixed;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 54px;
  width: calc(100% - 135px);
  max-width: 440px;
  min-width: 304px;
  background-color: var(--bg3A95);
  z-index: 110;

  @media screen and (max-width: 510px) {
    max-width: none;
    width: 100%;
  }
}

.web-service-profile-header-left {
  cursor: pointer;
  width: 44px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;

  &:hover {
    background-color: var(--bg4);
  }
}

.web-service-profile-left-icon {
  width: 32px;
  height: 32px;
}

.web-service-profile-header-right {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.web-service-profile-header-right-button-wrap {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 44px;
  height: 44px;
  border-radius: 8px;

  &:hover {
    background-color: var(--bg4);
  }
}
