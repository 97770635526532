body.light {
  --reverseLight: black;
  --reverseDark: white;
  --black: black;
  --white: white;
  --blackA30: rgba(0, 0, 0, 0.3);
  --blackA50: rgba(0, 0, 0, 0.5);
  --whiteA60: rgba(255, 255, 255, 0.6);
  --bg1: #fcfeff;
  --bg2: #f2f5f7;
  --bg3: #e6eaed;
  --bg35: #e1e5e8;
  --bg3A95: rgba(230, 234, 237, 0.95);
  --bg4: #dadee3;
  --bg5: #ced3d9;
  --border1: #edf0f2;
  --border2: #e1e5e8;
  --border3: #d5d9de;
  --border4: #c9ced4;
  --border5: #bdc3c9;
  --text1: #222c36;
  --text2: #465563;
  --text3: #74828f;
  --text4: #95a2ad;
  --primary1: #00b2a1;
  --primary1A80: #2ebeb0;
  --primary1A60: #5cc9bf;
  --primary1A40: #8ad4ce;
  --primary1A20: #b8dfde;
  --primary2: #14ccba;
  --primary2A80: #3ed2c4;
  --primary2A60: #68d8ce;
  --primary2A40: #92ded8;
  --primary2A20: #bce4e3;
  --primary3: #7edbd3;
  --textRed: #e5174b;
  --areaRed: #f2184f;
  --textBlue: #0c7ff2;
  --textBlueAdjust: #0077ff;
  --areaBlue: #0080ff;
  --areaBlueB90: #0073e5;
  --areaBlueB80: #0066cc;
  --starYellow: #eeab00; //updated
  --bottomSheetShadow: 0 0 36px 0 rgba(0, 0, 0, 0.05);
}

body.dark {
  --reverseLight: white;
  --reverseDark: black;
  --black: black;
  --white: white;
  --whiteA60: rgba(255, 255, 255, 0.6);
  --blackA30: rgba(0, 0, 0, 0.3);
  --blackA50: rgba(0, 0, 0, 0.5);
  --bg1: #33404d;
  --bg2: #26303b;
  --bg3: #1a2129;
  --bg35: #151b21;
  --bg3A95: rgba(26, 33, 41, 0.95);
  --bg4: #0e1317;
  --bg5: #030405;
  --border1: #495866;
  --border2: #3d4a57;
  --border3: #313d47;
  --border4: #252f38;
  --border5: #1a2129;
  --text1: #f2f5f7;
  --text2: #c5ced6;
  --text3: #8e9cab;
  --text4: #718191;
  --primary1: #28c7b7;
  --primary1A80: #25a69b;
  --primary1A60: #22847e;
  --primary1A40: #206362;
  --primary1A20: #1d4245;
  --primary2: #31bdaf;
  --primary2A80: #2c9e94;
  --primary2A60: #287e79;
  --primary2A40: #235f5e;
  --primary2A20: #1f4044;
  --primary3: #6bc9c1;
  --textRed: #ff3366;
  --areaRed: #e5174b;
  --textBlue: #2693ff;
  --textBlueAdjust: #006be5;
  --areaBlue: #0080ff;
  --areaBlueB90: #0073e5;
  --areaBlueB80: #0066cc;
  --starYellow: #eeb524; //updated
  --bottomSheetShadow: 0 0 36px 0 rgba(0, 0, 0, 0.4);
}

// Font Size

.fs-10 {
  font-size: 10px;
}
.fs-11 {
  font-size: 11px;
}
.fs-12 {
  font-size: 12px;
}
.fs-13 {
  font-size: 13px;
}
.fs-14 {
  font-size: 14px;
}
.fs-15 {
  font-size: 15px;
}
.fs-16 {
  font-size: 16px;
}
.fs-18 {
  font-size: 18px;
}
.fs-20 {
  font-size: 20px;
}
.fs-24 {
  font-size: 24px;
}
.fs-28 {
  font-size: 28px;
}
.fs-32 {
  font-size: 32px;
}

// Font Weight

.fw-400 {
  font-weight: 400;
}

.fw-500 {
  font-weight: 500;
}

.fw-700 {
  font-weight: 700;
}

// Line-Height (13=PN, 16=P)

.lh-PN {
  line-height: 1.33;
}
.lh-P {
  line-height: 1.66;
}

body {
  --cubiz-normal: cubic-bezier(0.5, 0, 0.5, 1);
}
