.web-service-auth-permission-title {
  font-size: 20px;
  line-height: 1.4;
  color: var(--text1);
  font-weight: 700;
  padding: 36px 16px;
}

.web-service-auth-permission-itemset {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px 16px 36px 16px;
}

.web-service-auth-permission-item {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 16px;
}

.web-service-auth-permission-icon-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  border-radius: 24px;
  background-color: var(--primary1A20);
}

.web-service-auth-permission-icon {
  width: 32px;
}

.web-service-auth-permission-item-text-wrap {
  display: flex;
  flex-direction: column;
  flex: 1 1 0;
  gap: 8px;
  padding: 2px 0 20px;
  border-bottom: 0.5px solid var(--border3);
}

.web-service-auth-permission-item-name {
  font-size: 16px;
  font-weight: 700;
  color: var(--text1);
}

.web-service-auth-permission-item-info {
  font-size: 14px;
  font-weight: 400;
  line-height: 1.6;
  color: var(--text2);
}
