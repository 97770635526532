@import url('https://cdn.jsdelivr.net/gh/orioncactus/pretendard@v1.3.6/dist/web/static/pretendard.css');

body {
  overflow-y: scroll;
  overflow-x: hidden;

  @media screen and (max-width: 510px) {
    overflow-y: auto;
  }
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: 'Pretendard Variable', Pretendard, -apple-system,
    BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans,
    Droid Sans, Helvetica Neue, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

button,
input,
textarea {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: text;
}

input[type='password' i] {
  font-family: sans-serif;
}

input::-ms-clear {
  display: none;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

input::placeholder {
  color: var(--text4);
}

textarea {
  cursor: text;
  font-size: 16px;
  font-weight: 400;
  color: var(--text2);
  line-height: 1.66;
}

textarea::placeholder {
  color: var(--text4);
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
  line-height: 1;
}

* {
  transition: 0.1s cubic-bezier(0.5, 0, 0, 0.8);
}

:root {
  --normal-transition: cubic-bezier(0.5, 0, 0, 0.8);
}

div {
  line-height: 1;
  font-weight: 400;
}

img {
  -webkit-user-drag: none;
}
